import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import logo from "../../../assets/images/header/Asset 5.png";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";
import { Link } from "react-router-dom";
import Store, { Context } from "../../context/store";
import { Fade } from "react-reveal";

function Header() {
  const [dropDown, setDropDown] = useState(false);
  const {
    dispatch,
    state: { user_data },
  } = useContext(Context);
  const isLogin = user_data.isLogin;

  //----------OutsideClick--------------------
  function useOutsideClick(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setDropDown(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef);

  return (
    <Container>
      <Wrapper className="wrapper">
        <Content>
          <Logo to="/">
            <img src={logo} alt="logo" />
          </Logo>
          <CoverNave>
            <Nav>
              <Item to="/">Home</Item>
              <Item to="/about">About</Item>
              <Item to="/careers">Careers</Item>
              {/* <Item to="/ourteam">Our Team</Item>
              <Item >Achievements</Item>
              <Item >Testimonials</Item>
              <Item >Contact</Item> */}
            </Nav>
            <Items onClick={() => setDropDown(!dropDown)}>
              <Icon>
                <i className="ri-menu-3-fill"></i>
              </Icon>
            </Items>
            {dropDown && (
              <DropDown ref={wrapperRef}>
                <Fade top delay={100}>
                  <Menu className="mob" to="/">
                    Home
                  </Menu>
                </Fade>
                <Fade top delay={200}>
                  <Menu className="mob" to="/about">
                    About
                  </Menu>
                </Fade>
                <Fade top delay={300}>
                  <Menu className="mob" to="/careers">
                    Careers
                  </Menu>
                </Fade>
                <Fade top delay={300}>
                  <Menu to="/ourteam">Our Team</Menu>
                </Fade>
                <Fade top delay={350}>
                  <Menu to="/gallery">Gallery</Menu>
                </Fade>
                <Fade top delay={400}>
                  <Menu to="/achievements">Achievements</Menu>
                </Fade>
                <Fade top delay={500}>
                  <Menu to="/testimonials">Testimonials</Menu>
                </Fade>
                <Fade top delay={500}>
                  <Menu to="/blog">Blog</Menu>
                </Fade>
                <Fade top delay={600}>
                  <Menu to="/contact">Contact</Menu>
                </Fade>
              </DropDown>
            )}
            {isLogin ? (
              <Button type={isLogin} to="/dashboard">
                Dashboard
              </Button>
            ) : (
              <Button
                data-tooltip-id="my-tooltip"
                data-tooltip-content="Existing students login to access"
                to="/dashboard/auth/"
              >
                Login
                <Tooltip id="my-tooltip" place="bottom" />
              </Button>
            )}
          </CoverNave>
        </Content>
      </Wrapper>
    </Container>
  );
}

export default Header;

const Container = styled.div`
  width: 80%;
  z-index: 100;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 0 0 20px 20px;
  padding: 20px 0;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%);
`;
const Hamburger = styled.div`
  display: none;
  @media all and (max-width: 1080px) {
    display: block;
    font-size: 30px;
    color: rgb(14, 45, 94);
    margin-left: 20px;
    cursor: pointer;
  }
`;
const Wrapper = styled.div``;
const DropDown = styled.div`
  position: absolute;
  z-index: 1000;
  top: 107px;
  right: 300px;
  @media all and (max-width: 1440px) {
    top: 92px;
  }
  @media all and (max-width: 1280px) {
    top: 87px;
    right: 284px;
  }
  @media all and (max-width: 1080px) {
    top: 116px;
    right: 204px;
  }
  @media all and (max-width: 768px) {
    top: 95px;
    right: 0;
    text-align: center;
    width: 100%;
  }
`;
const Menu = styled(Link)`
  background-color: #fff;
  padding: 18px 30px;
  margin-bottom: 5px;
  color: #000;
  text-decoration: none;
  display: block;
  border-radius: 5px;
  transition: 0.4s ease;

  &:hover {
    transition: 0.4s ease;
    background-color: #bcbcff;
  }
  &.mob {
    display: none;
    @media all and (max-width: 768px) {
      display: block;
    }
  }
  @media all and (max-width: 768px) {
    padding: 10px 30px;
  }
`;
const Icon = styled.div`
  margin-left: 10px;
  font-size: 24px;
`;
const Items = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 20px;
`;
const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Logo = styled(Link)`
  width: 9%;
  text-decoration: none;
  img {
    display: block;
    width: 100%;
  }
  @media all and (max-width: 1080px) {
    width: 18%;
  }
  @media all and (max-width: 480px) {
    width: 30%;
  }
`;
const CoverNave = styled.div`
  width: 45%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media all and (max-width: 1500px) {
    width: 75%;
  }
  @media all and (max-width: 1280px) {
    width: 80%;
  }
  @media all and (max-width: 1080px) {
    justify-content: flex-end;
    align-items: flex-end;
  }
`;
const Item = styled(Link)`
  /* width: 100%; */
  cursor: pointer;
  color: #1d1c1c;
  text-decoration: none;

  transition: 0.4s ease;
  &:hover {
    color: #d50b00;
  }
`;
const Button = styled(Link)`
  width: 120px;
  border: 2px solid #d50b00;
  color: #d50b00;
  cursor: pointer;
  text-decoration: none;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  @media all and (max-width: 400px) {
    height: 30px;
    width: 90px;
    font-size: ${({ type }) => (type ? "12px" : "14px")};
  }
`;
const Nav = styled.div`
  display: flex;
  width: 50%;
  justify-content: space-between;
  align-items: center;
  margin-right: 50px;
  @media all and (max-width: 1080px) {
    display: none;
  }
`;
