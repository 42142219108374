import React, { useState } from "react";
import styled from "styled-components";
import singleTick from "../../../../assets/images/dashboard/icons/Single tick.svg";
import doubleTick from "../../../../assets/images/dashboard/icons/Double tick.svg";
import ButtonLoader from "../../loader/ButtonLoader";
function TextBreif({
  content,
  completeStatus,
  handleFunction,
  status,
  hide,
  loading,
}) {
  const [isWatched, setWatched] = useState(false);

  return (
    <TextBar>
      <Text>
        <div
          dangerouslySetInnerHTML={{
            __html: content,
          }}
        />
      </Text>
      {hide == "completed" ? (
        <Statuss>
          <img src={doubleTick} alt="" />
        </Statuss>
      ) : (
        <Status
          type={status}
          className={loading && !status && "no-padding"}
          onClick={() => {
            setWatched(true);
            handleFunction(completeStatus.id);
          }}
        >
          {loading && !status ? (
            <ButtonLoader />
          ) : status ? (
            <img src={doubleTick} alt="" />
          ) : (
            <img src={singleTick} alt="" />
          )}
        </Status>
      )}
      <Bullet></Bullet>
    </TextBar>
  );
}

export default TextBreif;

const TextBar = styled.div`
  background-color: #fff;
  width: fit-content;
  padding: 20px;
  display: flex;
  justify-content: flex-end;
  position: relative;
  border-radius: 12px;
  align-items: flex-end;
  margin-bottom: 20px;
`;
const Text = styled.h2`
  color: var(--blue);
  font-family: "mont-medium";
  font-size: 16px;
  width: 90%;
`;
const Statuss = styled.div`
  width: 35px;
  cursor: pointer;
  pointer-events: none;
  /* background-color: red; */
  background-color: #0e2d5e;
  border-radius: 8px;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  margin-left: 20px;
  img {
    width: 100%;
    display: block;
  }
`;
const Status = styled.div`
  width: 35px;
  cursor: pointer;
  background-color: ${({ type }) => (type ? "#0e2d5e" : " rgb(164, 164, 164)")};
  border-radius: 8px;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  margin-left: 20px;
  img {
    width: 100%;
    display: block;
  }
  &.no-padding {
    padding: 0;
  }
`;
const Bullet = styled.div`
  width: 15px;
  height: 15px;
  background-color: var(--blue);
  position: absolute;
  left: -60px;
  top: 30px;
  border-radius: 50%;
  @media (max-width: 480px) {
    left: -31px;
  }
`;
