import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link, useParams } from "react-router-dom";
import bg from "../../../../assets/images/blog/banner.png";
import Footer from "../../../includes/home/Footer";
import Header from "../../../includes/home/Header";
import { accountsConfig } from "../../../../AxiosConfig";

function BlogSingle() {
  const [getData, setData] = useState();
  const [dataList, setDataList] = useState([]);
  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const { id } = useParams();

  //blog listing
  useEffect(() => {
    accountsConfig.get(`general/list-blogs/`, {}).then((res) => {
      const StatusCode = res.data.app_data.StatusCode;
      const Data = res.data.app_data.data;
      if (StatusCode == 6000) {
        setDataList(Data);
      } else {
        setDataList([]);
      }
    });
  }, []);

  //blog single listing
  useEffect(() => {
    accountsConfig.get(`general/single-blogs/${id}`, {}).then((res) => {
      const StatusCode = res.data.app_data.StatusCode;
      const Data = res.data.app_data.data;
      console.log(Data);
      if (StatusCode == 6000) {
        setData(Data);
      } else {
        setData([]);
      }
    });
  }, [id]);

  //splitting the date
  const dateToSplit = getData?.created_at;
  const words = dateToSplit?.split(" ");
  useEffect(() => {
    if (words?.length > 0) {
      setDay(words[0]);
      setMonth(words[1]);
      setYear(words[2]);
    }
  }, [words]);
  return (
    <Container>
      <Banner
        style={{
          backgroundImage: `url(${getData?.image})`,
          backgroundSize: "cover",
        }}
      >
        <Header />
      </Banner>
      <Wrapper className="wrapper">
        <Content>
          <Label>Blog</Label>
          <NewsTitle>{getData?.title} </NewsTitle>
          <Subtitle></Subtitle>
          <Detail>
            <Date>
              <Day>{day}</Day>
              <Line></Line>
              <Month>{month}</Month>
              <Line></Line>
              <Day>{year}</Day>
              <Line></Line>
              <Social>
                <Icon>
                  <i class="ri-instagram-line"></i>
                </Icon>
                <Icon>
                  <i class="ri-facebook-fill"></i>
                </Icon>
                <Icon>
                  <i class="ri-linkedin-fill"></i>
                </Icon>
              </Social>
            </Date>
            <DescriptionSection>
              <Subtitle>{getData?.sub_title}</Subtitle>
              <Description>
                <div
                  dangerouslySetInnerHTML={{
                    __html: getData?.description,
                  }}
                />
              </Description>
            </DescriptionSection>
            <RelatedEvents>
              {dataList.map((item, index) => (
                <RelatedCard key={index} to={`/blog/${item.slug}`}>
                  <ImageContainerBanner>
                    <img src={item.thumbnail} alt="images" />
                  </ImageContainerBanner>
                  <TitleDes>{item.title}</TitleDes>
                </RelatedCard>
              ))}
            </RelatedEvents>
          </Detail>
          {/* <Tags>
            <ul>
              <li></li>
            </ul>
          </Tags> */}
        </Content>
      </Wrapper>
      <Footer />
    </Container>
  );
}

export default BlogSingle;

const Container = styled.div``;
const Subtitle = styled.h2`
  color: #9e9e9e;
  font-weight: 600;
  margin-bottom: 20px;
  @media all and (max-width: 400px) {
    font-size: 18px;
  }
`;
const Wrapper = styled.div`
  margin-bottom: 50px;
`;
const Label = styled.div`
  margin-bottom: 30px;
  color: #9e9e9e;
  cursor: pointer;
  &.active {
    color: var(--blue);
    font-weight: 600;
  }
`;
const Content = styled.div`
  margin-top: 50px;
`;
const Title = styled.h1`
  color: var(--blue);
  font-weight: 800;
  width: 70%;
  padding-bottom: 30px;
  @media all and (max-width: 1080px) {
    width: 100%;
    font-size: 28px;
  }
  @media all and (max-width: 400px) {
    font-size: 18px;
  }
`;
const ImageContainerBanner = styled.div`
  margin-bottom: 10px;
`;
const ImageContainer = styled.div`
  height: 600px;
  overflow: hidden;
  margin-bottom: 50px;
  @media all and (max-width: 1080px) {
    margin-bottom: 0;
  }
  @media all and (max-width: 768px) {
    height: unset;
  }
`;
const Banner = styled.div`
  /* background: url(${bg}); */
  height: 62vh;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  @media all and (max-width: 1560px) {
    background-position: center;
  }
  @media all and (max-width: 1280px) {
    background-position: 30%;
  }
  @media all and (max-width: 768px) {
  }
  @media all and (max-width: 480px) {
    background-position: center;
    background-size: contain;
  }
`;
const Detail = styled.div`
  display: flex;
  justify-content: space-between;
  @media all and (max-width: 1080px) {
    flex-direction: column;
  }
`;
const Date = styled.div`
  width: 6%;
  @media all and (max-width: 1080px) {
    width: 35%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  @media all and (max-width: 768px) {
    width: 45%;
    margin-top: 10px;
  }
  @media all and (max-width: 640px) {
    width: 100%;
    justify-content: flex-start;
    gap: 20px;
  }
  @media all and (max-width: 400px) {
    gap: 10px;
    align-items: flex-start;
  }
`;
const Day = styled.h2`
  color: #858486;
  font-weight: 600;
  margin-bottom: 10px;
  margin-top: 10px;
  @media all and (max-width: 400px) {
    font-size: 14px;
  }
`;
const Line = styled.div`
  width: 40px;
  height: 1px;
  background-color: var(--blue);
  @media all and (max-width: 1080px) {
    display: none;
  }
`;
const Month = styled.h3`
  margin-bottom: 10px;
  color: #858486;
  margin-top: 10px;
  @media all and (max-width: 400px) {
    font-size: 14px;
  }
`;
const Social = styled.div`
  display: flex;
  margin-top: 10px;
`;
const Icon = styled.div`
  color: #858486;
  margin-right: 10px;
`;
const DescriptionSection = styled.div`
  width: 70%;
  @media all and (max-width: 1280px) {
    width: 60%;
  }
  @media all and (max-width: 1080px) {
    width: 100%;
    margin-top: 10px;
  }
`;
const NewsTitle = styled.h1`
  color: var(--blue);
  font-weight: 600;
  margin-bottom: 20px;
  @media all and (max-width: 400px) {
    font-size: 18px;
  }
`;
const Description = styled.p`
  font-size: 20px;
  line-height: 40px;
  text-align: justify;
  @media all and (max-width: 400px) {
    font-size: 14px;
    line-height: 26px;
  }
`;
const RelatedEvents = styled.div`
  width: 15%;
  @media all and (max-width: 1280px) {
    width: 20%;
  }
  @media all and (max-width: 1080px) {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 20px;
    gap: 30px;
  }
  @media all and (max-width: 480px) {
    flex-direction: column;
  }
`;
const RelatedCard = styled(Link)`
  margin-bottom: 20px;
  text-decoration: none;
  cursor: pointer;
  display: block;
  @media all and (max-width: 400px) {
    margin-bottom: 10px;
  }
`;
const TitleDes = styled.h3`
  font-size: 14px;
  @media all and (max-width: 400px) {
    font-size: 14px;
  }
`;
