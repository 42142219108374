import React, { useState, useRef, useEffect, useContext } from "react";
import styled from "styled-components";
import round from "../../../assets/images/learning/Asset 3.png";
import arrow from "../../../assets/images/arrow/Asset 7.png";
import {
  advanced_level,
  course_levels,
  enrty_level,
  secondary_level,
} from "../../general/arrays";
import { Fade } from "react-reveal";
import { Link } from "react-router-dom";
import { Context } from "../../context/store";
import { accountsConfig } from "../../../AxiosConfig";

function Learning() {
  const [isActive, setActive] = useState("entry");
  const [isSecondTab, setSecondTab] = useState("");
  const [isThirdTab, setThirdTab] = useState("");
  const [getCourse, setCourses] = useState([]);
  const [courseName, setCourseName] = useState();
  const [secCourseName, setSecCourseName] = useState();
  const [description, setDescription] = useState();
  const [secDescription, setSecDescription] = useState();

  const divRef = useRef([]);
  const handleClick = (index) => {
    const targetDiv = divRef.current[index];
    if (targetDiv) {
      targetDiv.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  //courses list
  useEffect(() => {
    accountsConfig.get("courses/programmes/", {}).then((res) => {
      const StatusCode = res.data.app_data.StatusCode;
      const Data = res.data.app_data.data;
      if (StatusCode == 6000) {
        setCourses(Data);
        setActive(Data[0].id);
        setDescription(Data[0].description);
        setCourseName(Data[0].name);
      } else {
      }
    });
  }, []);

  const toggleFirstTab = (item) => {
    setSecondTab(null);
    if (item.id == isActive) {
      setActive(null);
    } else {
      setActive(item.id);
    }
  };
  const toggleSecondTab = (item) => {
    setActive(null);
    setThirdTab("")
    if (item.id == isSecondTab) {
      setSecondTab(null);
    } else {
      setSecondTab(item.id);
    }
  };
  const toggleThirdTab = (item) => {
    setActive(null);
    setSecondTab("")
    if (item.id == isThirdTab) {
      setThirdTab(null);
    } else {
      setThirdTab(item.id);
    }
  };
  const {
    state: { user_data },
  } = useContext(Context);
  const isLogin = user_data.isLogin;

  function breakString(text) {
    const words = text.split(" ");
    const result = [];
    let currentLine = [];

    for (const word of words) {
      currentLine.push(word);

      if (currentLine.length > 1) {
        result.push(currentLine.join(" "));
        currentLine = [];
      }
    }

    if (currentLine.length > 0) {
      result.push(currentLine.join(" "));
    }

    return result.join("\n");
  }
  return (
    <Container>
      <Fade bottom delay={400}>
        <Wrapper className="wrapper">
          <Content>
            <Sub>Learning Levels</Sub>
            <Title>Our Different Course Levels</Title>
            <Tabs>
              {getCourse?.slice(0, 3).map((item) => (
                <TabSwitch
                  key={item.id}
                  onClick={() => {
                    toggleFirstTab(item);
                    setDescription(item.description);
                    setCourseName(item.name);
                  }}
                  className={isActive === item.id ? "active" : ""}
                >
                  <ArrowContainer className={isActive === item.id && "rotate"}>
                    <img src={round} alt="advanced english course online" />
                  </ArrowContainer>
                  <Text>
                    <h2>{breakString(item.name)}</h2>
                  </Text>
                </TabSwitch>
              ))}
              {isActive && (
                <Detail>
                  <DetailTitle>{courseName}</DetailTitle>
                  <Description> {description}</Description>
                  {!isLogin && (
                    <CoverDetail>
                      <TitleSecs href="href=https://api.whatsapp.com/send?phone=7907139138">
                        <span>ENQUIRE NOW</span>
                        <Icon>
                          <i className="ri-whatsapp-line"></i>
                        </Icon>
                      </TitleSecs>
                      <TitleSec className="login" to="/dashboard/auth/">
                        <span>LOGIN NOW</span>
                        <Icon className="ic">
                          <img
                            src={arrow}
                            alt="learn english through whatsapp"
                          />
                        </Icon>
                      </TitleSec>
                    </CoverDetail>
                  )}
                </Detail>
              )}
            </Tabs>
            <Tabs>
              {getCourse?.slice(3, 7).map((item) => (
                <TabSwitch
                  key={item.id}
                  onClick={() => {
                    toggleSecondTab(item);
                    setSecDescription(item.description);
                    setSecCourseName(item.name);
                  }}
                  className={isSecondTab === item.id ? "active sec" : "sec"}
                >
                  <ArrowContainer
                    className={isSecondTab === item.id && "rotate"}
                  >
                    <img src={round} alt="icon" />
                  </ArrowContainer>
                  <Text className="sec">
                    <h2>{breakString(item.name)}</h2>
                    {/* <span>Level</span> */}
                  </Text>
                </TabSwitch>
              ))}
              {isSecondTab && (
                <Detail
                // className={
                //   isActive === item.id ? "curtain-raise" : "curtain-lower"
                // }
                >
                  <DetailTitle>{secCourseName}</DetailTitle>
                  <Description> {secDescription}</Description>
                  {!isLogin && (
                    <CoverDetail>
                      <TitleSecs href="href=https://api.whatsapp.com/send?phone=7907139138">
                        <span>ENQUIRE NOW</span>
                        <Icon>
                          <i className="ri-whatsapp-line"></i>
                        </Icon>
                      </TitleSecs>
                      <TitleSec className="login" to="/dashboard/auth/">
                        <span>LOGIN NOW</span>
                        <Icon className="ic">
                          <img src={arrow} alt="icon" />
                        </Icon>
                      </TitleSec>
                    </CoverDetail>
                  )}
                </Detail>
              )}
            </Tabs>
            <Tabs>
              {getCourse?.slice(7).map((item) => (
                <TabSwitch
                  key={item.id}
                  onClick={() => {
                    toggleThirdTab(item);
                    setSecDescription(item.description);
                    setSecCourseName(item.name);
                  }}
                  className={isThirdTab === item.id ? "active sec" : "sec"}
                >
                  <ArrowContainer
                    className={isThirdTab === item.id && "rotate"}
                  >
                    <img src={round} alt="icon" />
                  </ArrowContainer>
                  <Text className="sec">
                    <h2>{breakString(item.name)}</h2>
                    {/* <span>Level</span> */}
                  </Text>
                </TabSwitch>
              ))}
              {isThirdTab && (
                <Detail
                // className={
                //   isActive === item.id ? "curtain-raise" : "curtain-lower"
                // }
                >
                  <DetailTitle>{secCourseName}</DetailTitle>
                  <Description> {secDescription}</Description>
                  {!isLogin && (
                    <CoverDetail>
                      <TitleSecs href="href=https://api.whatsapp.com/send?phone=7907139138">
                        <span>ENQUIRE NOW</span>
                        <Icon>
                          <i className="ri-whatsapp-line"></i>
                        </Icon>
                      </TitleSecs>
                      <TitleSec className="login" to="/dashboard/auth/">
                        <span>LOGIN NOW</span>
                        <Icon className="ic">
                          <img src={arrow} alt="icon" />
                        </Icon>
                      </TitleSec>
                    </CoverDetail>
                  )}
                </Detail>
              )}
            </Tabs>

            {/* ------------------------Tab & Mobile--------------------------- */}
            <MobileTab>
              {getCourse?.map((item, index) => (
                <MobileCover key={index}>
                  <TabSwitch
                    key={item.id}
                    onClick={() => {
                      toggleFirstTab(item);
                      setDescription(item.description);
                      setCourseName(item.name);
                    }}
                    className={isActive === item.id ? "active" : ""}
                  >
                    <ArrowContainer
                      className={isActive === item.id && "rotate"}
                    >
                      <img src={round} alt="icon" />
                    </ArrowContainer>
                    <Text>
                      <h2>{breakString(item.name)}</h2>
                      {/* <span>Level</span> */}
                    </Text>
                  </TabSwitch>
                  {isActive === item.id && (
                    <MobDetail
                      ref={(el) => (divRef.current[index] = el)}
                      className={isActive === item.id && "active"}
                    >
                      <CoverContainer>
                        <DetailTitle>{item.title}</DetailTitle>
                        <Description> {item.description}</Description>
                        {!isLogin && (
                          <CoverDetail>
                            <TitleSecs href="https://api.whatsapp.com/send?phone=7907139138">
                              <span>ENQUIRE NOW</span>
                              <Icon>
                                <i className="ri-whatsapp-line"></i>
                              </Icon>
                            </TitleSecs>
                            <TitleSec className="login" to="/dashboard/auth/">
                              <span>LOGIN NOW</span>
                              <Icon className="ic">
                                <img src={arrow} alt="icon" />
                              </Icon>
                            </TitleSec>
                          </CoverDetail>
                        )}
                      </CoverContainer>
                    </MobDetail>
                  )}
                </MobileCover>
              ))}
            </MobileTab>
          </Content>
        </Wrapper>
      </Fade>
    </Container>
  );
}

export default Learning;

const Container = styled.div`
  background-color: #f4f4f4;
  padding: 80px 0;
  @media all and (max-width: 400px) {
    padding: 30px 0;
  }
`;
const Wrapper = styled.div``;
const CoverContainer = styled.div``;
const MobileCover = styled.div``;
const CoverDetail = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media all and (max-width: 480px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;
const MobDetail = styled.div`
  opacity: 0;
  margin-left: 50px;

  &.active {
    opacity: 1;
    transition: 0.4s ease;
    @media all and (max-width: 400px) {
      margin-top: 20px;
      width: unset;
      margin-left: 0;
    }
  }
  transition: 0.4s ease;
  background-color: #fff;
  padding: 20px 30px 30px 50px;
  margin-bottom: 20px;
  border-radius: 0 0 50px 50px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;

  @media all and (max-width: 400px) {
    box-shadow: none;
    border-radius: 20px;
    margin-top: 10px;
    padding: 20px 20px 30px 20px;
  }
`;
const MobileTab = styled.div`
  display: none;
  @media all and (max-width: 640px) {
    display: block;
  }
`;
const Content = styled.div``;
const Sub = styled.h4`
  color: var(--blue);
  padding-bottom: 20px;
  border-bottom: 1px solid var(--blue);
  width: fit-content;
`;
const Title = styled.h1`
  font-family: "mont-bold";
  font-size: 30px;
  margin-top: 20px;
  color: var(--blue);
  margin-bottom: 50px !important;
`;
const Tabs = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 30px;
  transition: 0.4s ease;
  @media all and (max-width: 640px) {
    display: none;
  }
`;
const TabSwitch = styled.div`
  transition: 0.4s ease;
  width: 30%;
  height: 105px;
  margin-bottom: 10px;
  position: relative;
  border-radius: 0 10px 10px 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #adadad;
  &.sec {
    width: 24%;
  }
  &.active {
    background-color: #ff4945;
    /* margin-bottom: 0; */
  }
  &:last-child {
    border-radius: 0 50px 50px 0;
  }
  @media all and (max-width: 1600px) {
    height: 110px;
  }
  @media all and (max-width: 1440px) {
    height: 80px;
  }
  @media all and (max-width: 1080px) {
    height: 75px;
  }
  @media all and (max-width: 640px) {
    width: 90%;
    margin-bottom: 30px;
    margin-left: 50px;
  }
  @media all and (max-width: 400px) {
    margin-left: 40px;
    height: 50px;
    border-radius: 0 10px 10px 0;
    margin-bottom: 18px;
  }
`;
const TabSwitchs = styled.div`
  transition: 0.4s ease;
  width: 30%;
  height: 90px;
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #adadad;
  &.active {
    background-color: #ff4945;
    margin-bottom: 0;
  }
  &:last-child {
    border-radius: 0 50px 50px 0;
  }
  @media all and (max-width: 1600px) {
    height: 110px;
  }
  @media all and (max-width: 1400px) {
    height: 90px;
  }
  @media all and (max-width: 1080px) {
    height: 75px;
  }
  @media all and (max-width: 640px) {
    width: 100%;
    margin-bottom: 30px;
  }
`;
const Text = styled.div`
  color: #fff;
  &.sec {
    font-size: 12px;
  }

  h2 {
    margin: 0;
    font-family: "mont-medium";
    @media all and (max-width: 1440px) {
      font-size: 16px;
      word-break: break-word;
      width: 100px;
    }
    @media all and (max-width: 1080px) {
      font-size: 16px;
      margin-right: 20px;
    }
  }
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
const ArrowContainer = styled.div`
  position: absolute;
  transition: 0.4s ease;
  left: -60px;
  width: 30%;
  &.rotate {
    transition: 0.4s ease;
    transform: rotate(90deg);
  }
  img {
    display: block;
    width: 100%;
  }
  @media all and (max-width: 1600px) {
    width: 130px;
  }
  @media all and (max-width: 1440px) {
    width: 100px;
  }
  @media all and (max-width: 1080px) {
    width: 90px;
  }
  @media all and (max-width: 400px) {
    width: 70px;
    left: -40px;
  }
`;
const Detail = styled.div`
  width: 80%;
  margin: 0 auto;
  background: #fff;
  padding: 30px;
  border-radius: 0 0 50px 50px;
  transition: 0.4s ease;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  @media all and (max-width: 640px) {
    display: none;
  }
`;
const DetailTitle = styled.h3`
  font-family: "mont-bold";
  color: var(--blue);
  font-size: 26px;
`;
const Description = styled.p`
  line-height: 28px;
`;
const TitleSecs = styled.a`
  display: flex;
  text-decoration: none;
  padding-left: 10px;
  font-size: 14px;
  cursor: pointer;
  align-items: center;
  border-radius: 0 50px 50px 0;
  background-color: var(--blue);
  color: #fff;
  width: fit-content;
  &.login {
    background-color: #fff;
    color: #0e2d5e;
    position: relative;
    span {
      border-top: 1px solid #d50b00;
      border-bottom: 1px solid #d50b00;
      padding-right: 20px;
      padding: 8px 20px 5px 5px;
      color: #0e2d5e;
      font-family: "mont-medium";
      font-size: 14px;
    }
  }
  span {
    margin-right: 20px;
  }
  @media all and (max-width: 480px) {
    margin-bottom: 10px;
  }
`;
const TitleSec = styled(Link)`
  display: flex;
  text-decoration: none;
  padding-left: 10px;
  font-size: 14px;
  cursor: pointer;
  align-items: center;
  border-radius: 0 50px 50px 0;
  background-color: var(--blue);
  color: #fff;
  width: fit-content;
  &.login {
    background-color: #fff;
    color: #0e2d5e;
    position: relative;
    span {
      border-top: 1px solid #d50b00;
      border-bottom: 1px solid #d50b00;
      padding-right: 20px;
      padding: 8px 20px 5px 5px;
      color: #0e2d5e;
      font-family: "mont-medium";
      font-size: 14px;
    }
  }
  span {
    margin-right: 20px;
  }
  @media all and (max-width: 480px) {
    margin-bottom: 10px;
  }
`;
const Icon = styled.div`
  border: 1px solid var(--blue);
  color: var(--blue);
  border-radius: 50%;
  font-size: 20px;
  background-color: #fff;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  &.ic {
    position: absolute;
    right: 3px;
    border: none;
  }
`;
