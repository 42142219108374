import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Header from "../../includes/home/Header";
import Footer from "../../includes/home/Footer";
import bg from "../../../assets/images/blog/banner.png";
import imgPic from "../../../assets/images/blog/blog.png";
import { Zoom } from "react-reveal";
import api from "../../general/api";
import { accountsConfig } from "../../../AxiosConfig";

export default function Blog() {
  const [getNews, setNews] = useState("news");
  const [getData, setData] = useState([]);

  //blog listing
  useEffect(() => {
    accountsConfig.get(`general/list-blogs/`, {}).then((res) => {
      const StatusCode = res.data.app_data.StatusCode;
      const Data = res.data.app_data.data;
      if (StatusCode == 6000) {
        setData(Data);
      } else {
        setData([]);
      }
    });
  }, []);
  return (
    <>
      <Container>
        <Header />
        <Banner>
          <Header />
          <Zoom>
            <Text>Blog</Text>
          </Zoom>
        </Banner>
        <Wrapper className="wrapper">
          <Content>
            <Label>Blog</Label>

            <NewsSection>
              {getData.map((item, index) => (
                <NewsCover key={index}>
                  <ImageContainer>
                    <img src={item.thumbnail} alt={item.alt} />
                  </ImageContainer>
                  <DetailSection>
                    <NewsTitle>{item.title}</NewsTitle>
                    <Date>{item.created_at}</Date>
                    <Description>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.description.slice(0, 500),
                        }}
                      />
                    </Description>
                    <Line></Line>
                    <LoadMore to={`/blog/${item.slug}`}>
                      Load More
                      <Arrow>
                        <i class="ri-arrow-right-double-line"></i>
                      </Arrow>
                    </LoadMore>
                  </DetailSection>
                </NewsCover>
              ))}
            </NewsSection>
          </Content>
        </Wrapper>
      </Container>
      <Footer />
    </>
  );
}

const Container = styled.div`
  margin-bottom: 100px;
  @media all and (max-width: 480px) {
    margin-bottom: 200px;
  }
`;
const Text = styled.h1`
  color: #fff;
  font-family: mont-medium;
  font-size: 50px;
  margin-left: 350px;
  margin-top: 150px;
  @media all and (max-width: 768px) {
    display: none;
  }
`;
const Wrapper = styled.div``;
const Content = styled.div`
  margin-top: 50px;
  @media all and (max-width: 480px) {
    margin-top: 0;
  }
`;
const Banner = styled.div`
  background: url(${bg});
  height: 62vh;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  @media all and (max-width: 1560px) {
    background-position: center;
  }
  @media all and (max-width: 1280px) {
    background-position: 30%;
  }
  @media all and (max-width: 768px) {
  }
  @media all and (max-width: 480px) {
    background-position: center;
    background-size: contain;
  }
`;

const Label = styled.div`
  margin-bottom: 30px;
  color: #9e9e9e;
  cursor: pointer;
  &.active {
    color: var(--blue);
    font-weight: 600;
  }
`;
const NewsSection = styled.div`
  @media all and (max-width: 980px) {
    margin-bottom: 150px;
  }
`;
const NewsCover = styled.div`
  display: flex;
  gap: 50px;
  margin-bottom: 50px;
  @media all and (max-width: 980px) {
    flex-direction: column;
  }
`;
const ImageContainer = styled.div`
  width: 40%;
  @media all and (max-width: 980px) {
    width: 80%;
    margin: 0 auto;
  }
  @media all and (max-width: 480px) {
    margin: 0;
    width: 100%;
  }
`;
const DetailSection = styled.div`
  width: 50%;
  @media all and (max-width: 980px) {
    width: 100%;
  }
`;
const NewsTitle = styled.h1`
  color: var(--blue);
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 20px;
  @media all and (max-width: 480px) {
    font-size: 22px;
  }
`;
const Date = styled.div`
  color: #969696;
  background-color: #f2f2f2;
  width: fit-content;
  margin-bottom: 20px;
  padding: 6px 12px;
  border-radius: 5px;
`;
const Description = styled.p`
  font-size: 16px;
  text-align: justify;
  line-height: 32px;
  margin-bottom: 30px;
  @media all and (max-width: 480px) {
    font-size: 14px;
    line-height: 26px;
  }
`;
const Line = styled.div`
  background-color: #cecece;
  height: 1px;
  width: 100%;
  margin-bottom: 20px;
`;
const LoadMore = styled(Link)`
  display: flex;
  color: #505050;
  cursor: pointer;
`;
const Arrow = styled.div`
  color: var(--blue);
`;
