import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Header from "../../includes/home/Header";
import { Fade, Zoom } from "react-reveal";
import Footer from "../../includes/home/Footer";
import bg from "../../../assets/images/testimonials/TestimonialsBanner.jpg";
import { testimonials } from "../../general/arrays";
import qouteleft from "../../../assets/images/testimonials/Asset 10.svg";
import qouteRight from "../../../assets/images/testimonials/Asset 9.svg";
import arrow from "../../../assets/images/testimonials/Asset 21.svg";
import play from "../../../assets/images/testimonials/Asset 23.svg";
import { Rate } from "antd";
import VideoModal from "../../includes/modal/VideoModal";
import Slider from "react-slick";
import api from "../../general/api";
import def from "../../../assets/images/testimonials/defualt.png";
import { Helmet } from "react-helmet";
import { accountsConfig } from "../../../AxiosConfig";

function Testimonials() {
  const [isModal, setModal] = useState(false);
  const [videoUrl, setVideoUrl] = useState();
  const [getTestimonials, setTestimonials] = useState([]);
  const [displayedItems, setDisplayedItems] = useState(4);

  const showMoreAchievements = () => {
    setDisplayedItems(getTestimonials.length);
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };
  useEffect(() => {
    accountsConfig.get(`company_profile/testimonials/`).then((res) => {
      const Data = res.data.app_data.data;
      if (res.data.app_data.StatusCode == 6000) {
        setTestimonials(Data);
      }
    });
  }, []);
  return (
    <>
      <Helmet>
        <title>Testimonials | English Cafe</title>
        <meta
          name="description"
          content="Discover what our satisfied learners have to say about their experiences at our English Training Centre. Real stories, real results."
        />
        <meta name="keywords" content="React, Helmet, Metadata" />
      </Helmet>
      <Container>
        <Banner>
          <Header />
          <Zoom>
            <Text>Testimonials</Text>
          </Zoom>
        </Banner>
        <Fade bottom>
          <Content>
            <Wrapper>
              <Content>
                <SubTitle>Testimonials</SubTitle>

                {/* ---------------------------web section ---------------------------------- */}
                <BarSection>
                  {getTestimonials
                    ?.slice(0, displayedItems)
                    .map((item, index) => (
                      <Bar key={item.id}>
                        {index % 2 === 0 ? ( // If index is even, show image on the left
                          <>
                            <ImageSection
                              className={item.image == null && "none"}
                              type={index % 2}
                            >
                              <Image>
                                {item.image == null ? (
                                  <img src={def} alt={item.name} />
                                ) : (
                                  <img src={item.image} alt={item.name} />
                                )}
                              </Image>
                              <Round></Round>
                            </ImageSection>
                            <DetailBox type={index % 2}>
                              <Cover>
                                <QouteLeft>
                                  <img src={qouteleft} alt="icon" />
                                </QouteLeft>
                                <Middle>
                                  <Description>{item.quote} </Description>
                                  <Bottom>
                                    <CoverVideo>
                                      <Name>{item.name} </Name>
                                      {item.video ? (
                                        <CoverButton
                                          onClick={() => {
                                            setVideoUrl(item.video);
                                            setModal(true);
                                          }}
                                        >
                                          <PlayButton
                                            id="play-video"
                                            className="video-play-button"
                                          >
                                            <div class="waves wave-1"></div>
                                            <div class="waves wave-2"></div>
                                            <div class="waves wave-3"></div>
                                            <img src={play} alt="play button" />
                                          </PlayButton>
                                          <span>Play video</span>
                                        </CoverButton>
                                      ) : (
                                        ""
                                      )}
                                    </CoverVideo>
                                    <Rating>
                                      <Rate
                                        disabled
                                        allowHalf
                                        defaultValue={item.rating_count}
                                      />
                                    </Rating>
                                  </Bottom>
                                </Middle>
                                <QouteLeft className="right">
                                  <img src={qouteRight} alt="icon" />
                                </QouteLeft>
                              </Cover>
                              <Arrow type={index % 2}>
                                <img src={arrow} alt="arrow" />
                              </Arrow>
                            </DetailBox>
                          </>
                        ) : (
                          // If index is odd, show image on the right
                          <>
                            <DetailBox>
                              <Cover>
                                <QouteLeft>
                                  <img src={qouteleft} alt="icon" />
                                </QouteLeft>
                                <Middle>
                                  <Description>{item.quote} </Description>
                                  <Bottom>
                                    <CoverVideo>
                                      <Name>{item.name} </Name>
                                      {item.video ? (
                                        <CoverButton
                                          onClick={() => {
                                            setVideoUrl(item.video);
                                            setModal(true);
                                          }}
                                        >
                                          <PlayButton
                                            id="play-video"
                                            className="video-play-button"
                                          >
                                            <div class="waves wave-1"></div>
                                            <div class="waves wave-2"></div>
                                            <div class="waves wave-3"></div>
                                            <img src={play} alt="play button" />
                                          </PlayButton>
                                          <span>Play video</span>
                                        </CoverButton>
                                      ) : (
                                        ""
                                      )}
                                    </CoverVideo>
                                    <Rating>
                                      <Rate
                                        disabled
                                        allowHalf
                                        defaultValue={item.rating_count}
                                      />
                                    </Rating>
                                  </Bottom>
                                </Middle>
                                <QouteLeft className="right">
                                  <img src={qouteRight} alt="icon" />
                                </QouteLeft>
                              </Cover>
                              <Arrow type={index % 2}>
                                <img className="odd" src={arrow} alt="arrow" />
                              </Arrow>
                            </DetailBox>
                            <ImageSection type={item.image}>
                              <Image>
                                {item.image == null ? (
                                  <img src={def} alt={item.name} />
                                ) : (
                                  <img src={item.image} alt={item.name} />
                                )}
                              </Image>
                              <Round></Round>
                            </ImageSection>
                          </>
                        )}
                      </Bar>
                    ))}
                  {getTestimonials.length > 4 &&
                    displayedItems < getTestimonials.length && (
                      <View onClick={() => showMoreAchievements()}>
                        View all
                        <Icon>
                          <i className="ri-arrow-right-s-line"></i>
                        </Icon>
                      </View>
                    )}
                </BarSection>

                {/* --------------------------tab section---------------------------------- */}
                <TabBarSection>
                  {getTestimonials.map((item, index) => (
                    <Bar key={item.id}>
                      <ImageSection>
                        <Image>
                          <img src={item.image} alt={item.name} />
                        </Image>
                        <Round></Round>
                      </ImageSection>

                      <DetailBox>
                        <Name>{item.name} </Name>
                        <Cover>
                          <QouteLeft>
                            <img src={qouteleft} alt="icon" />
                          </QouteLeft>
                          <Middle>
                            <Description>{item.quote} </Description>
                            <Bottom>
                              <CoverVideo>
                                {item.video ? (
                                  <CoverButton
                                    onClick={() => {
                                      setVideoUrl(item.video);
                                      setModal(true);
                                    }}
                                  >
                                    <PlayButton
                                      id="play-video"
                                      className="video-play-button"
                                    >
                                      <div class="waves wave-1"></div>
                                      <div class="waves wave-2"></div>
                                      <div class="waves wave-3"></div>
                                      <img src={play} alt="play button" />
                                    </PlayButton>
                                    <span>Play video</span>
                                  </CoverButton>
                                ) : (
                                  ""
                                )}
                              </CoverVideo>
                              <Rating>
                                <Rate
                                  disabled
                                  allowHalf
                                  defaultValue={item.rating}
                                />
                              </Rating>
                            </Bottom>
                          </Middle>
                          <QouteLeft className="right">
                            <img src={qouteRight} alt="icon" />
                          </QouteLeft>
                        </Cover>
                      </DetailBox>
                    </Bar>
                  ))}
                </TabBarSection>

                {/* -----------------------------------mobile section --------------------------- */}
                <MobileSection>
                  <Slider {...settings}>
                    {getTestimonials.map((item, index) => (
                      <Bar key={item.id}>
                        <ImageSection>
                          <Image>
                            <img src={item.image} alt={item.name} />
                          </Image>
                          <Round></Round>
                        </ImageSection>

                        <DetailBox>
                          <Name>{item.name} </Name>
                          <Cover>
                            <QouteLeft>
                              <img src={qouteleft} alt="icon" />
                            </QouteLeft>
                            <Middle>
                              <Description>{item.quote} </Description>
                              <Bottom>
                                <CoverVideo>
                                  {item.video ? (
                                    <CoverButton
                                      onClick={() => {
                                        setVideoUrl(item.video);
                                        setModal(true);
                                      }}
                                    >
                                      <PlayButton
                                        id="play-video"
                                        className="video-play-button"
                                      >
                                        <div class="waves wave-1"></div>
                                        <div class="waves wave-2"></div>
                                        <div class="waves wave-3"></div>
                                        <img src={play} alt="play button" />
                                      </PlayButton>
                                      <span>Play video</span>
                                    </CoverButton>
                                  ) : (
                                    ""
                                  )}
                                </CoverVideo>
                                <Rating>
                                  <Rate
                                    disabled
                                    allowHalf
                                    defaultValue={item.rating}
                                  />
                                </Rating>
                              </Bottom>
                            </Middle>
                            <QouteLeft className="right">
                              <img src={qouteRight} alt="icon" />
                            </QouteLeft>
                          </Cover>
                        </DetailBox>
                      </Bar>
                    ))}
                  </Slider>
                </MobileSection>
              </Content>
            </Wrapper>
          </Content>
        </Fade>
        <CallBanner>
          <span>Our Call Center :</span>
          <a href="tel:+91 7907139138">+91 7907139138</a>
        </CallBanner>

        <VideoModal setModal={setModal} isModal={isModal} videoUrl={videoUrl} />
      </Container>
      <Footer />
    </>
  );
}

export default Testimonials;

const Container = styled.div``;
const TabBarSection = styled.div`
  display: none;
  @media all and (max-width: 768px) {
    display: block;
  }
  @media all and (max-width: 480px) {
    display: none;
  }
`;
const MobileSection = styled.div`
  display: none;
  @media all and (max-width: 480px) {
    display: block;
  }
`;
const Wrapper = styled.div`
  width: 70%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  @media all and (max-width: 1440px) {
    width: 80%;
  }
  @media all and (max-width: 1080px) {
    flex-direction: column;
  }
  @media all and (max-width: 480px) {
    width: 90%;
  }
`;
const Banner = styled.div`
  background: url(${bg});
  height: 62vh;
  background-repeat: no-repeat;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @media all and (max-width: 1560px) {
    background-position: center;
  }
  @media all and (max-width: 1280px) {
    background-position: 30%;
  }
  @media all and (max-width: 480px) {
    background: url(${bg});
    background-position: center;
    background-size: cover;
  }
`;
const Content = styled.div`
  padding: 40px 0;
`;
const Text = styled.h1`
  color: #fff;
  font-family: mont-medium;
  font-size: 50px;
  margin-right: 400px;
  margin-top: -10px;
`;
const LeftSection = styled.div`
  background-color: rgb(245, 244, 245);
  width: 34%;
  /* height: 58px 30px; */
  /* min-height: 485px; */
  padding: 60px 30px;
  box-sizing: border-box;
  @media all and (max-width: 1080px) {
    width: 100%;
    padding: 60px 30px 30px 30px;
  }
`;
const SubTitle = styled.h4`
  color: var(--blue);
  border-bottom: 1px solid var(--blue);
  padding-bottom: 10px;
  display: inline-block;
  font-family: "mont-medium";
  margin-bottom: 60px;
  @media all and (max-width: 480px) {
    margin-bottom: 10px;
  }
`;

const CallBanner = styled.div`
  background-color: #f5f4f5;
  padding: 30px 0;
  text-align: center;
  span {
    color: #d92119;
    font-size: 30px;
    @media all and (max-width: 480px) {
      font-size: 18px;
    }
  }
  a {
    margin-left: 10px;
    color: var(--blue);
    font-family: "mont-bold";
    text-decoration: none;
    font-size: 35px;
    @media all and (max-width: 480px) {
      font-size: 20px;
    }
  }
  @media all and (max-width: 480px) {
    padding: 20px 0;
  }
`;

const BarSection = styled.div`
  @media all and (max-width: 768px) {
    display: none;
  }
`;
const Bar = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 50px;
  @media all and (max-width: 768px) {
    flex-direction: column;
    position: relative;
    margin-bottom: 150px;
  }
  @media all and (max-width: 480px) {
    margin-bottom: 0px;
    margin-top: 100px;
    width: 98% !important;
  }
`;
const Image = styled.div`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  /* border: 3px solid var(--blue); */
  overflow: hidden;
  object-fit: cover;
  img {
    width: 100%;
    filter: contrast(0.8);
    @media all and (max-width: 400px) {
      height: 100%;
    }
  }
`;
const DetailBox = styled.div`
  width: 90%;
  background-color: #f4f4f4;
  /* display: flex; */
  position: relative;
  /* justify-content: center; */
  border-radius: ${({ type }) =>
    type === 0 ? "0 30px 30px 30px" : "30px 0px 30px 30px"};
  padding: 20px 0;
  @media all and (max-width: 768px) {
    padding: 120px 0 20px;
    border-radius: 20px;
    position: unset;
  }
  @media all and (max-width: 480px) {
    width: 100%;
    margin-right: 10px;
  }
`;
const Description = styled.p`
  font-size: 14px;
  line-height: 28px;
`;
const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media all and (max-width: 480px) {
    /* flex-direction: column; */
  }
`;
const Name = styled.h4`
  color: var(--blue);
  font-family: "mont-medium";
  font-size: 18px;
  margin-right: 30px;
  @media all and (max-width: 480px) {
    margin-right: 0;
    margin-bottom: 10px;
  }
  @media all and (max-width: 768px) {
    text-align: center;
  }
`;
const ImageSection = styled.div`
  border: ${({ type }) => (type == null ? "none" : "1px solid var(--blue)")};
  border-radius: 50%;
  padding: 10px;
  margin-right: ${({ type }) => (type === 0 ? "60px" : 0)};
  margin-left: ${({ type }) => (type === 0 ? "0" : "60px")};
  &.none {
    border: none;
  }
  @media all and (max-width: 768px) {
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-left: 0;
    margin-right: 0;
  }
`;
const Rating = styled.div``;
const Round = styled.div``;
const QouteLeft = styled.div`
  width: 110px;
  margin-right: 20px;
  &.right {
    display: flex;
    margin-right: 0;
  }
  @media all and (max-width: 1080px) {
    justify-content: flex-end;
    align-items: flex-end;
    margin-bottom: 50px;
  }
  @media all and (max-width: 480px) {
    width: 200px;
  }
`;
const Cover = styled.div`
  display: flex;
  width: 90%;
  margin: 0 auto;
`;
const Middle = styled.div``;
const Arrow = styled.div`
  position: absolute;
  left: -80px;
  left: ${({ type }) => (type === 0 ? "-80px" : "unset")};
  right: ${({ type }) => (type === 0 ? "0" : "-80px")};
  top: 0px;
  width: 100px;
  img {
    &.odd {
      transform: scaleX(-1);
    }
  }
  @media all and (max-width: 768px) {
    display: none;
  }
`;

const CoverVideo = styled.div`
  display: flex;
  align-items: center;
`;
const PlayButton = styled.div`
  width: 25px;
  display: flex;
  cursor: pointer;
  margin-right: 15px;
  align-items: center;
`;
const CoverButton = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  span {
    font-size: 14px;
  }
`;
const View = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
  align-items: center;
  text-transform: capitalize;
  color: var(--blue);
  cursor: pointer;
  font-family: "mont-bold";
  i {
    color: var(--red);
    font-family: "mont-bold";
  }
`;
const Icon = styled.div`
  border: 1px solid var(--red);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
