import trainer from "../../assets/images/careers/trainer.svg";
import councellor from "../../assets/images/careers/counceller.svg";
import ach from "../../assets/images/achievements/Achieve.svg";
import image1 from "../../assets/images/testimonials/Asset 1-8.png";
import image2 from "../../assets/images/testimonials/Asset 2-8.png";
import image3 from "../../assets/images/testimonials/Asset 3-8.png";
import image4 from "../../assets/images/testimonials/Asset 4-8.png";
import image5 from "../../assets/images/testimonials/Asset 5-8.png";
import image6 from "../../assets/images/testimonials/test.jpg";

//founders
import shibil from "../../assets/images/ourteam/SHIBIL MOHAMMED-FOUNDER & CEO.jpg";
import anshif from "../../assets/images/ourteam/ANSHIF K-FOUNDER & MANAGING DIRECTOR.jpg";
import gafoor from "../../assets/images/ourteam/ABDUL GAFOOR KV-FOUNDER & MANAGING DIRECTOR.jpg";
import junaid from "../../assets/images/ourteam/JUNAID T-FOUNDER & MANAGING DIRECTOR.jpg";

//gallery
import imager1 from "../../assets/images/gallery/EC/Asset 19.png";
import imager2 from "../../assets/images/gallery/EC/Asset 20.png";
import imager3 from "../../assets/images/gallery/EC/Asset 18.png";
import imager4 from "../../assets/images/gallery/EC/Asset 17.png";
import imager5 from "../../assets/images/gallery/EC/Asset 16.png";
import imager6 from "../../assets/images/gallery/EC/Asset 15.png";
import imager7 from "../../assets/images/gallery/EC/Asset 12.png";
import imager8 from "../../assets/images/gallery/EC/Asset 11.png";
import imager9 from "../../assets/images/gallery/EC/Asset 14.png";
import imager10 from "../../assets/images/gallery/EC/Asset 13.png";

//video gallery
import thumb1 from "../../assets/images/gallery/EC/Asset 15.png";
import thumb2 from "../../assets/images/gallery/EC/Asset 19.png";
import thumb3 from "../../assets/images/gallery/EC/Asset 17.png";
import thumb4 from "../../assets/images/gallery/EC/Asset 16.png";

//achievements
import logo_ach from "../../assets/images/achievements/logo.png";
import ribbon_ach from "../../assets/images/achievements/ribbon.png";
import grp_ach from "../../assets/images/achievements/group.png";
import vineeth_ach from "../../assets/images/achievements/vineeth.png";
import lakh_ach from "../../assets/images/achievements/1lack.png";

export const class_about = [
  {
    id: 1,
    title: "Basic Grammar Proficiency",
  },
  {
    id: 12,
    title: "Excellent Practical Classes",
  },
  {
    id: 31,
    title: "Calling Activities",
  },
  {
    id: 14,
    title: "Speak-up Sessions",
  },
  {
    id: 15,
    title: "Reading Comprehension",
  },
  {
    id: 16,
    title: "Writing Tasks",
  },
  {
    id: 17,
    title: "Listening Activities",
  },
  {
    id: 18,
    title: "Exercises Promoting Learning",
  },
];

export const enrty_level = {
  id: 1,
  title: "Entry Level",
  description:
    "Students who require the building up of a basic foundation in the English language, are categorised and trained in this level. Those struggling to frame even a sentence properly in English, will find themselves adept at writing out excellent essays even, and speaking with fluency and confidence by the end of their training session with us.",
};
export const secondary_level = {
  id: 2,
  title: "Secondary Level",
  description:
    "Students who require the building up of a basic foundation in the English language, are categorised and trained in this level. Those struggling to frame even a sentence properly in English, will find themselves adept at writing out excellent essays even, and speaking with fluency and confidence by the end of their training session with us.",
};
export const advanced_level = {
  id: 3,
  title: "Advanced Level",
  description:
    "Students who require the building up of a basic foundation in the English language, are categorised and trained in this level. Those struggling to frame even a sentence properly in English, will find themselves adept at writing out excellent essays even, and speaking with fluency and confidence by the end of their training session with us.",
};

export const course_levels = [
  {
    id: 1,
    title: "Entry Level",
    label: "Level",
    description:
      "Students who require the building up of a basic foundation in the English language, are categorised and trained in this level. Those struggling to frame even a sentence properly in English, will find themselves adept at writing out excellent essays even, and speaking with fluency and confidence by the end of their training session with us.",
  },
  {
    id: 2,
    title: "Secondary Level",
    label: "Level",
    description:
      "Students who require the building up of a basic foundation in the English language, are categorised and trained in this level. Those struggling to frame even a sentence properly in English, will find themselves adept at writing out excellent essays even, and speaking with fluency and confidence by the end of their training session with us.",
  },
  {
    id: 3,
    title: "Advanced Level",
    label: "Level",
    description:
      "Students who require the building up of a basic foundation in the English language, are categorised and trained in this level. Those struggling to frame even a sentence properly in English, will find themselves adept at writing out excellent essays even, and speaking with fluency and confidence by the end of their training session with us.",
  },
  {
    id: 4,
    title: "SPEAK-UP",
    label: "Sessions",
    description:
      "Students who require the building up of a basic foundation in the English language, are categorised and trained in this level. Those struggling to frame even a sentence properly in English, will find themselves adept at writing out excellent essays even, and speaking with fluency and confidence by the end of their training session with us.",
  },
  {
    id: 5,
    title: "IELTS",
    label: null,
    description:
      "Students who require the building up of a basic foundation in the English language, are categorised and trained in this level. Those struggling to frame even a sentence properly in English, will find themselves adept at writing out excellent essays even, and speaking with fluency and confidence by the end of their training session with us.",
  },
  {
    id: 6,
    title: "Crack Your",
    label: "Interview",
    description:
      "Students who require the building up of a basic foundation in the English language, are categorised and trained in this level. Those struggling to frame even a sentence properly in English, will find themselves adept at writing out excellent essays even, and speaking with fluency and confidence by the end of their training session with us.",
  },
  {
    id: 7,
    title: "EC Junior",
    label: "Course",
    description:
      "Students who require the building up of a basic foundation in the English language, are categorised and trained in this level. Those struggling to frame even a sentence properly in English, will find themselves adept at writing out excellent essays even, and speaking with fluency and confidence by the end of their training session with us.",
  },
];

export const careers_list = [
  {
    id: 1,
    title: "Counsellor",
    type: "Full time",
    image: councellor,
  },
  {
    id: 2,
    title: "Language Trainer",
    type: "Full time",
    image: trainer,
  },
  {
    id: 3,
    title: "Counsellor",
    type: "Part time",
    image: councellor,
  },
  {
    id: 4,
    title: "Language Trainer",
    type: "Full time",
    image: trainer,
  },
  {
    id: 5,
    title: "Counsellor",
    type: "Part time",
    image: councellor,
  },
  {
    id: 6,
    title: "Language Trainerr",
    type: "Full time",
    image: trainer,
  },
  {
    id: 7,
    title: "Counsellor",
    type: "Full time",
    image: councellor,
  },
  {
    id: 8,
    title: "Language Trainer",
    type: "Full time",
    image: trainer,
  },
];

// achievements
export const achievements = [
  {
    id: 1,
    title: "Nulla facilisi. Integer volutpat",
    description:
      "Nulla facilisi. Integer volutpat variusipsum, sit amet fringilla magna facilisis",
    image: ach,
  },
  {
    id: 2,
    title: "Nulla facilisi. Integer volutpat",
    description:
      "Nulla facilisi. Integer volutpat variusipsum, sit amet fringilla magna facilisis",
    image: ach,
  },
  {
    id: 3,
    title: "Nulla facilisi. Integer volutpat",
    description:
      "Nulla facilisi. Integer volutpat variusipsum, sit amet fringilla magna facilisis",
    image: ach,
  },
  {
    id: 4,
    title: "Nulla facilisi. Integer volutpat",
    description:
      "Nulla facilisi. Integer volutpat variusipsum, sit amet fringilla magna facilisis",
    image: ach,
  },
  {
    id: 5,
    title: "Nulla facilisi. Integer volutpat",
    description:
      "Nulla facilisi. Integer volutpat variusipsum, sit amet fringilla magna facilisis",
    image: ach,
  },
  {
    id: 6,
    title: "Nulla facilisi. Integer volutpat",
    description:
      "Nulla facilisi. Integer volutpat variusipsum, sit amet fringilla magna facilisis",
    image: ach,
  },
  {
    id: 7,
    title: "Nulla facilisi. Integer volutpat",
    description:
      "Nulla facilisi. Integer volutpat variusipsum, sit amet fringilla magna facilisis",
    image: ach,
  },
  {
    id: 8,
    title: "Nulla facilisi. Integer volutpat",
    description:
      "Nulla facilisi. Integer volutpat variusipsum, sit amet fringilla magna facilisis",
    image: ach,
  },
];

//star rating
export const testimonials = [
  {
    id: 1,
    image: image1,
    rating: 3.5,
    name: "Mohammed Shiyas",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed dui eros, condimentum elementum lorem dapibus, porta semper nunc.Vivamus ultrices et metus non bibendum. Nunc faucibus non nisl eget ultricies. Integer tortor lorem, volutpat ut odio vitae, gravidascelerisque tortor. Maecenas porta ex et euismod ornare. Donec eu justo felis. Sed accumsan ex venenatis lorem sollicitudin male",
  },
  {
    id: 2,
    image: image2,
    rating: 5,
    video: "https://www.youtube.com/watch?v=co8tfeT2JOI",
    name: "Shyam Kumar",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed dui eros, condimentum elementum lorem dapibus, porta semper nunc.Vivamus ultrices et metus non bibendum. Nunc faucibus non nisl eget ultricies. Integer tortor lorem, volutpat ut odio vitae, gravidascelerisque tortor. Maecenas porta ex et euismod ornare. Donec eu justo felis. Sed accumsan ex venenatis lorem sollicitudin male",
  },
  {
    id: 3,
    image: image3,
    rating: 3,
    name: "Anjali Menon",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed dui eros, condimentum elementum lorem dapibus, porta semper nunc.Vivamus ultrices et metus non bibendum. Nunc faucibus non nisl eget ultricies. Integer tortor lorem, volutpat ut odio vitae, gravidascelerisque tortor. Maecenas porta ex et euismod ornare. Donec eu justo felis. Sed accumsan ex venenatis lorem sollicitudin male",
  },
  {
    id: 4,
    image: image4,
    rating: 4,
    name: "Keerthana",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed dui eros, condimentum elementum lorem dapibus, porta semper nunc.Vivamus ultrices et metus non bibendum. Nunc faucibus non nisl eget ultricies. Integer tortor lorem, volutpat ut odio vitae, gravidascelerisque tortor. Maecenas porta ex et euismod ornare. Donec eu justo felis. Sed accumsan ex venenatis lorem sollicitudin male",
  },
  {
    id: 5,
    image: image5,
    rating: 3.5,
    name: "Wazeer Ahammed",
    video: "https://www.youtube.com/watch?v=uLNzXdnZCLo",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed dui eros, condimentum elementum lorem dapibus, porta semper nunc.Vivamus ultrices et metus non bibendum. Nunc faucibus non nisl eget ultricies. Integer tortor lorem, volutpat ut odio vitae, gravidascelerisque tortor. Maecenas porta ex et euismod ornare. Donec eu justo felis. Sed accumsan ex venenatis lorem sollicitudin male",
  },
  {
    id: 6,
    image: image6,
    rating: 4.5,
    name: "Liyana",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed dui eros, condimentum elementum lorem dapibus, porta semper nunc.Vivamus ultrices et metus non bibendum. Nunc faucibus non nisl eget ultricies. Integer tortor lorem, volutpat ut odio vitae, gravidascelerisque tortor. Maecenas porta ex et euismod ornare. Donec eu justo felis. Sed accumsan ex venenatis lorem sollicitudin male",
  },
];

export const founders = [
  {
    id: 1,
    name: "Mohammed Shibil",
    designation: "CEO",
    image: shibil,
    alt: "Mohammed Shibil CEO of English Cafe",
  },
  {
    id: 2,
    name: "Anshif K",
    designation: "Managing Director",
    image: anshif,
    alt: "Anshif K Managing Director of English Cafe",
  },
  {
    id: 3,
    name: "Abdul Gafoor",
    designation: "Managing Director",
    image: gafoor,
    alt: "Abdul Gafoor Managing Director of English Cafe",
  },
  {
    id: 4,
    name: "Junaid",
    designation: "Managing Director",
    image: junaid,
    alt: "Junaid Managing Director of English Cafe",
  },
];

export const gallery_images = [
  {
    id: "ec_1Q7",
    slot_one: imager1,
    slot_two: imager2,
    slot_three: imager3,
    slot_four: imager4,
  },
  {
    id: "ec_1Q5",
    slot_one: imager5,
    slot_two: imager6,
    slot_three: imager7,
    slot_four: imager8,
  },
  {
    id: "ec_1Q4",
    slot_one: imager9,
    slot_two: imager10,
  },
];

// export const video_clips = [
//   {
//     id: "EC_54QW",
//     thumbnail: thumb1,
//     video: "https://youtu.be/PKt2JBj7GL4",
//   },
//   {
//     id: "EC_534QW",
//     thumbnail: thumb2,
//     video: "https://youtu.be/H-HsNIZsuhE",
//   },
//   {
//     id: "EC_544QW",
//     thumbnail: thumb3,
//     video: "https://youtu.be/itr1-NrVAy4",
//   },
//   {
//     id: "EC_545QW",
//     thumbnail: thumb4,
//     video: "https://youtu.be/uLNzXdnZCLo",
//   },
// ];

export const achievements_list = [
  {
    id: "EC232323",
    image: logo_ach,
    title:
      "On April 29th, 2020 English Cafe began its journey from the confines of a small bedroom",
  },
  {
    id: "EC22323",
    image: ribbon_ach,
    title:
      "In September 2020, we set up our own office, our staff count being a mere 6",
  },
  {
    id: "EC2323",
    image: grp_ach,
    title:
      "In 2021, we won the “ Best Emerging Brand Award” and the “ National Integration Award”.",
  },
  {
    id: "EC42323",
    image: vineeth_ach,
    title:
      "In 2022, we had the South Indian’s favourite, Vineeth Sreenivasan join our family, as the Brand Ambassador.",
  },
  {
    id: "ECr32323",
    image: lakh_ach,
    title:
      "2023 has us hit a count of 1 Lakh successful English Cafe students.",
  },
];
