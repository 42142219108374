import React from "react";
import styled from "styled-components";
import image from "../../../assets/images/nodata/Team.svg";

function NoCareers() {
  return (
    <Container>
      <ImageContainer>
        <img src={image} alt="no data" />
      </ImageContainer>
      <Title>Our team is currently working at full capacity</Title>
    </Container>
  );
}

export default NoCareers;

const Container = styled.div`
  padding: 40px;
  background-color: #fff;
  border-radius: 10px;
  @media all and (max-width: 480px) {
    padding: 20px;
  }
`;
const ImageContainer = styled.div`
  width: 80%;
  margin: 0 auto;
  @media all and (max-width: 480px) {
    width: 100%;
  }
`;
const Title = styled.div`
  text-align: center;
  margin-top: 10px;
  @media all and (max-width: 480px) {
    font-size: 14px;
  }
`;
