import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import styled, { keyframes } from 'styled-components';

function Faq() {
  const [activeIndex, setActiveIndex] = useState(null);
  const [visibleItems, setVisibleItems] = useState([]);
  const faqRefs = useRef([]);

  const toggleFaq = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setVisibleItems((prev) => [...prev, entry.target.dataset.index]);
          }
        });
      },
      { threshold: 0.5 }
    );

    faqRefs.current.forEach((ref) => {
      if (ref) observer.observe(ref);
    });

    return () => {
      faqRefs.current.forEach((ref) => {
        if (ref) observer.unobserve(ref);
      });
    };
  }, []);

  const faq_data = [
    {
      question: "What is English Cafe Online?",
      answer: "English Cafe Online is an interactive platform that offers a variety of online English language courses customized to different proficiency levels. It provides flexible learning options, including spoken English classes and grammar courses."
    },
    {
      question: "How can I enroll in an Online Spoken English Course at English Cafe?",
      answer: "You can easily enroll in an Online Spoken English Course at English Cafe by visiting our website and following the registration process. We will connect you back providing further details."
    },
    {
      question: "What types of English language courses does English Cafe offer?",
      answer: "English Cafe offers a wide range of courses, including Online Spoken English Classes, English Grammar Courses, IELTS Coaching, and English Speaking Courses. Each course is designed to meet specific learning needs."
    },
    {
      question: "Is there an English Learning App available for English Cafe?",
      answer: "Yes, English Cafe provides an English Learning App that allows you to learn English online anytime, anywhere. The app includes access to various courses, interactive lessons, and progress tracking."
    },
    {
      question: "Where is English Cafe located?",
      answer: "English Cafe is located in Calicut, Kerala. We also offer online services to students across the globe, making it easy to learn English online from the comfort of your home."
    },
    {
      question: "What makes English Cafe the best IELTS Coaching Centre?",
      answer: "English Cafe is recognized as one of the best IELTS Coaching Centres in Calicut due to its experienced trainers, personalized coaching, and comprehensive study materials designed to ensure high success rates."
    },
    {
      question: "Can I learn English online at my own pace with English Cafe?",
      answer: "Absolutely! English Cafe Online allows you to learn English at your own pace, with flexible scheduling options and courses designed to fit your personal learning style and schedule."
    },
    {
      question: "What is included in the English Grammar Course at English Cafe?",
      answer: "The English Grammar Course at English Cafe covers essential grammar rules, sentence structure, and practical exercises to help you master English grammar."
    },
    {
      question: "Are there any free resources available on English Cafe Online?",
      answer: "English Cafe Online offers free resources, including sample lessons, quizzes, and articles, to help you get started with your English learning journey."
    },
    {
      question: "How can I improve my English speaking skills through English Cafe?",
      answer: "English Cafe provides targeted English Speaking Courses that focus on improving fluency, pronunciation, and conversational skills for beginner, intermediate as well as advanced learners."
    },
    {
      question: "Is English Cafe suitable for beginners?",
      answer: "Yes, English Cafe offers courses designed specifically for beginners, including Online Spoken English Classes and English Grammar Courses. Our beginner-friendly content ensures a smooth learning experience."
    },
    {
      question: "What are the benefits of learning English online with English Cafe?",
      answer: "Learning English online with English Cafe offers numerous benefits, including flexible schedules, personalized learning paths, access to experienced instructors, and the convenience of studying from anywhere."
    },
    {
      question: "How can I access the IELTS Coaching classes online at English Cafe?",
      answer: "You can access IELTS Coaching classes online by registering on the English Cafe website. Our virtual classrooms offer live sessions, recorded lessons, and practice tests to prepare you for the IELTS exam."
    },
    {
      question: "What is the duration of the Online English Language Courses at English Cafe?",
      answer: "The duration of our Online English Language Courses varies depending on the course type and level. Typically, courses take 2 months, with flexible learning options."
    },
    {
      question: "How can English Cafe help me achieve my English language goals?",
      answer: "English Cafe offers a comprehensive learning experience with a variety of courses, experienced instructors, and interactive tools. Whether you want to improve your grammar, speaking skills, or prepare for IELTS, we have the right course for you."
    }
  ];

  const faqSchema = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [
      {
        "@type": "Question",
        "name": "What is English Cafe Online?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "English Cafe Online is an interactive platform that offers a variety of online English language courses customized to different proficiency levels. It provides flexible learning options, including spoken English classes and grammar courses."
        }
      },
      {
        "@type": "Question",
        "name": "How can I enroll in an Online Spoken English Course at English Cafe?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "You can easily enroll in an Online Spoken English Course at English Cafe by visiting our website and following the registration process. We will connect you back providing further details."
        }
      },
      {
        "@type": "Question",
        "name": "What types of English language courses does English Cafe offer?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "English Cafe offers a wide range of courses, including Online Spoken English Classes, English Grammar Courses, IELTS Coaching, and English Speaking Courses. Each course is designed to meet specific learning needs."
        }
      },
      {
        "@type": "Question",
        "name": "Is there an English Learning App available for English Cafe?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Yes, English Cafe provides an English Learning App that allows you to learn English online anytime, anywhere. The app includes access to various courses, interactive lessons, and progress tracking."
        }
      },
      {
        "@type": "Question",
        "name": "Where is English Cafe located?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "English Cafe is located in Calicut, Kerala. We also offer online services to students across the globe, making it easy to learn English online from the comfort of your home."
        }
      },
      {
        "@type": "Question",
        "name": "What makes English Cafe the best IELTS Coaching Centre?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "English Cafe is recognized as one of the best IELTS Coaching Centres in Calicut due to its experienced trainers, personalized coaching, and comprehensive study materials designed to ensure high success rates."
        }
      },
      {
        "@type": "Question",
        "name": "Can I learn English online at my own pace with English Cafe?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Absolutely! English Cafe Online allows you to learn English at your own pace, with flexible scheduling options and courses designed to fit your personal learning style and schedule."
        }
      },
      {
        "@type": "Question",
        "name": "What is included in the English Grammar Course at English Cafe?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "The English Grammar Course at English Cafe covers essential grammar rules, sentence structure, and practical exercises to help you master English grammar."
        }
      },
      {
        "@type": "Question",
        "name": "Are there any free resources available on English Cafe Online?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "English Cafe Online offers free resources, including sample lessons, quizzes, and articles, to help you get started with your English learning journey."
        }
      },
      {
        "@type": "Question",
        "name": "How can I improve my English speaking skills through English Cafe?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "English Cafe provides targeted English Speaking Courses that focus on improving fluency, pronunciation, and conversational skills for beginner, intermediate as well as advanced learners."
        }
      },
      {
        "@type": "Question",
        "name": "Is English Cafe suitable for beginners?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Yes, English Cafe offers courses designed specifically for beginners, including Online Spoken English Classes and English Grammar Courses. Our beginner-friendly content ensures a smooth learning experience."
        }
      },
      {
        "@type": "Question",
        "name": "What are the benefits of learning English online with English Cafe?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Learning English online with English Cafe offers numerous benefits, including flexible schedules, personalized learning paths, access to experienced instructors, and the convenience of studying from anywhere."
        }
      },
      {
        "@type": "Question",
        "name": "How can I access the IELTS Coaching classes online at English Cafe?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "You can access IELTS Coaching classes online by registering on the English Cafe website. Our virtual classrooms offer live sessions, recorded lessons, and practice tests to prepare you for the IELTS exam."
        }
      },
      {
        "@type": "Question",
        "name": "What is the duration of the Online English Language Courses at English Cafe?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "The duration of our Online English Language Courses varies depending on the course type and level. Typically, courses take 2 months, with flexible learning options."
        }
      },
      {
        "@type": "Question",
        "name": "How can English Cafe help me achieve my English language goals?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "English Cafe offers a comprehensive learning experience with a variety of courses, experienced instructors, and interactive tools. Whether you want to improve your grammar, speaking skills, or prepare for IELTS, we have the right course for you."
        }
      }
    ]
  };

  return (
    <>
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(faqSchema)}</script>
      </Helmet>
      <Container>
        <Wrapper>
          <Sub>Frequently Asked Questions</Sub>
          <Title>FAQs</Title>
          <FaqList>
            {faq_data.map((item, index) => (
              <FaqItem
                key={index}
                isActive={activeIndex === index}
                ref={(el) => (faqRefs.current[index] = el)}
                data-index={index}
                isVisible={visibleItems.includes(index.toString())}
              >
                <Question onClick={() => toggleFaq(index)} isActive={activeIndex === index}>
                  <span>{item.question}</span>
                  <ToggleIcon>{activeIndex === index ? '-' : '+'}</ToggleIcon>
                </Question>
                <AnswerWrapper isActive={activeIndex === index}>
                  <Answer>{item.answer}</Answer>
                </AnswerWrapper>
              </FaqItem>
            ))}
          </FaqList>
        </Wrapper>
      </Container>
    </>
  );
}

export default Faq;

const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Container = styled.div`
  background-color: white;
  margin-bottom: -1px;
  padding: 80px 0;
  @media all and (max-width: 480px) {
    padding: 0px;
   padding-bottom: 40px;
  }
`;

const Wrapper = styled.div`
  max-width: 80%;
  margin: 0 auto;
  padding: 0 20px;
  @media all and (max-width: 480px) {
    max-width: 90%;;
  }
`;

const Sub = styled.h4`
  color: var(--blue);
  padding-bottom: 20px;
  border-bottom: 1px solid var(--blue);
  width: fit-content;
  margin-bottom: 20px;
  @media all and (max-width: 1080px) {
    margin-top: 60px;
  }
`;

const Title = styled.h1`
  color: var(--blue);
  font-family: "mont-bold";
  font-size: 35px;
  @media all and (max-width: 1600px) {
    font-size: 32px;
  }
  @media all and (max-width: 1080px) {
    font-size: 30px;
  }
  @media all and (max-width: 768px) {
    font-size: 28px;
  }
  @media all and (max-width: 480px) {
    font-size: 24px;
  }
`;

const FaqList = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const FaqItem = styled.div`
  background-color: ${({ isActive }) => (isActive ? 'rgb(14, 45, 94)' : '#E3E3E3')};
  border-radius: 6px;
  padding: 15px;
  color: ${({ isActive }) => (isActive ? 'white' : 'inherit')};
  border: none;
  transition: background-color 0.4s ease-in-out;
  opacity: 0;
  transform: translateY(20px);
  animation: ${({ isVisible }) => isVisible ? fadeInUp : 'none'} 0.6s ease-out forwards;
`;

const Question = styled.div`
font-family: "mont-regular";
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-weight: bold;
  color: ${({ isActive }) => (isActive ? 'white' : 'var(--blue)')};
  @media all and (max-width: 1600px) {
    font-size: 18px;
  }
  @media all and (max-width: 1080px) {
    font-size: 16px;
  }
  @media all and (max-width: 768px) {
    font-size: 14px;
  }
  @media all and (max-width: 480px) {
    font-size: 14px;
  }
`;

const ToggleIcon = styled.span`
  font-size: 18px;
  margin-left: 10px;
`;

const AnswerWrapper = styled.div`
  max-height: ${({ isActive }) => (isActive ? '300px' : '0')};
  overflow: hidden;
  transition: max-height 0.6s ease, padding 0.6s ease;
  padding-top: ${({ isActive }) => (isActive ? '10px' : '0')};
`;

const Answer = styled.div`
font-family: "mont-regular";
  font-size: 16px;
  line-height: 1.5;
  color: #fff;
  @media all and (max-width: 1600px) {
    font-size: 14px;
    line-height: 1.5;
  }
  @media all and (max-width: 1080px) {
    font-size: 12px;
    line-height: 1.5;
  }
  @media all and (max-width: 768px) {
    font-size: 12px;
    line-height: 1.5;
  }
  @media all and (max-width: 480px) {
    font-size: 12px;
    line-height: 1.5;
  }
`;
