import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import ReactModal from "react-modal";
import ReactPlayer from "react-player";
import $ from "jquery";

const VideoModal = ({ isModal, setModal, videoUrl, toggleFullscreen }) => {
  const handleOver = () => {
    setModal(false);
  };
  useEffect(() => {
    if (isModal) {
      $("html").addClass("modal-enabled");
    } else {
      $("html").removeClass("modal-enabled");
    }
  }, [isModal]);
  const playerStyle = {
    width: "100%",
    height: "573px",
    maxWidth: "500px",
    margin: "0 auto",
    // inset: "217px",
  };
  const modalStyle = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
    content: {
      width: "500px",
      height: "600px",
      // marginTop: "150px",
      margin: "0 auto",
      backgroundColor: "rgba(0, 0, 0, 0)",
      borderRadius: "4px",
      inset: "60px",
    },
  };
  const playerRef = useRef(null);

  return (
    <MainContainer type={isModal}>
      <ReactModal
        isOpen={isModal}
        //   onAfterOpen={p}
        onRequestClose={handleOver}
        contentLabel="Video Modal"
        style={modalStyle}
        className="reactmodal"
      >
        <Button>
          <button
            onClick={() => {
              handleOver();
              setModal(false);
            }}
          >
            Close
          </button>
        </Button>
        <ReactPlayer
          ref={playerRef}
          url={videoUrl}
          controls
          style={playerStyle}
          height="573px"
          margin="150px 0 0 0"
          width="100%"
          className="videoplayer"
          playing
        />
      </ReactModal>
    </MainContainer>
  );
};

export default VideoModal;
const Button = styled.div`
  z-index: 1000;
  button {
    background-color: #fff;
    width: 80px;
    border: none;
    border-radius: 10px;
    height: 30px;
  }
`;
const MainContainer = styled.div`
  margin-top: ${({ type }) => (type ? "150px" : "")};
`;
