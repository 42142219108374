import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import logo from "../../../assets/images/header/Asset 5.png";
import { Link } from "react-router-dom";
import bg from "../../../assets/images/dashboard/bg/BG.svg";
import logout from "../../../assets/images/dashboard/icons/Logout.svg";
import home from "../../../assets/images/profile/Home.svg";
import dash from "../../../assets/images/profile/Dashboard.svg";
import api from "../../general/api";
import { Context } from "../../context/store";

function Profile() {
  const [studentDetails, setStudentDetails] = useState();

  //import from store
  const {
    state: { user_data },
  } = useContext(Context);

  const studentId = user_data.student_id;
  const access_token = user_data.access_token;

  useEffect(() => {
    api
      .get(`accounts/student/${studentId}/`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((res) => {
        const Data = res.data.app_data.data;
        if (res.data.app_data.StatusCode == 6000) {
          setStudentDetails(Data);
        }
      });
  }, []);

  return (
    <Container>
      <Wrapper className="wrapper">
        <Header>
          <Logo>
            <img src={logo} alt="image" />
          </Logo>
          <NavBar>
            <Item to="/">
              <Icon className="b">
                <img src={home} alt="" />
              </Icon>
              <span>Home</span>
            </Item>
            <Item to="/dashboard">
              <Icon className="b">
                <img src={dash} alt="" />
              </Icon>
              <span>Dashboard</span>
            </Item>
            <ItemL>
              <Icon>
                <img src={logout} alt="" />
              </Icon>
              <span>Logout</span>
            </ItemL>
          </NavBar>
        </Header>
        <ProfileSection>
          <Title>Profile</Title>
          <Tabs>
            <TabItem>
              <Name>Full Name</Name>
              <Line></Line>
              <NameValue>{studentDetails?.name}</NameValue>
            </TabItem>
            <TabItem>
              <Name>Username</Name>
              <Line></Line>
              <NameValue>{studentDetails?.username}</NameValue>
            </TabItem>
            <TabItem>
              <Name>Password</Name>
              <Line></Line>
              <NameValue>{studentDetails?.password}</NameValue>
            </TabItem>
            <TabItem>
              <Name>Program</Name>
              <Line></Line>
              <NameValue>{studentDetails?.programmes}</NameValue>
            </TabItem>
          </Tabs>
        </ProfileSection>
      </Wrapper>
    </Container>
  );
}

export default Profile;

const Container = styled.div`
  background: url(${bg});
  padding: 40px;
  height: 100vh;
  @media all and (max-width: 480px) {
    padding: 20px;
  }
`;
const Wrapper = styled.div``;
const Header = styled.div`
  padding: 30px 60px;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  @media all and (max-width: 480px) {
    justify-content: center;
  }
`;
const Logo = styled.div`
  width: 10%;
  img {
    display: block;
    width: 100%;
  }
  @media all and (max-width: 480px) {
    display: none;
  }
`;
const NavBar = styled.div`
  display: flex;
  align-items: baseline;
`;
const ItemL = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #2a2a28;
  cursor: pointer;
  margin-right: 20px;
  &:last-child {
    margin-right: 0;
  }
  span {
    font-size: 14px;
  }
`;
const Item = styled(Link)`
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: #2a2a28;
  align-items: center;
  cursor: pointer;
  margin-right: 20px;
  &:last-child {
    margin-right: 0;
  }
  span {
    font-size: 14px;
  }
`;
const Icon = styled.div`
  width: 30px;
  margin-bottom: 10px;
  &.b {
    width: 22px;
  }
  img {
    display: block;
    width: 100%;
  }
`;
const ProfileSection = styled.div`
  width: 80%;
  margin: 0 auto;
  padding-top: 80px;
  @media all and (max-width: 640px) {
    width: 100%;
  }
`;
const Title = styled.h1`
  color: var(--blue);
  font-family: "mont-bold";
  margin-bottom: 10px;
  @media all and (max-width: 480px) {
    font-size: 26px;
  }
`;
const Tabs = styled.div``;
const TabItem = styled.div`
  display: flex;
  background-color: #fff;
  padding: 20px;
  align-items: center;
  margin-bottom: 5px;
  height: 50px;
  color: #2a2a28;
  border-radius: 10px;
  position: relative;
  &::before {
    width: 10px;
    border-radius: 10px 0 0 10px;
    background-color: var(--red);
    content: "";
    height: 100%;
    position: absolute;
    left: 0;
  }
  @media all and (max-width: 480px) {
    height: 40px;
  }
`;
const Name = styled.div`
  width: 200px;
  text-align: center;
  margin-right: 60px;
  @media all and (max-width: 480px) {
    width: 150px;
    margin-right: 30px;
    font-size: 14px;
  }
`;
const Line = styled.div`
  width: 1px;
  height: 45px;
  background-color: #d1d1d1;
`;
const NameValue = styled.div`
  width: 250px;
  text-align: left;
  margin-left: 80px;
  @media all and (max-width: 480px) {
    width: 150px;
    margin-left: 30px;
    font-size: 14px;
  }
`;
