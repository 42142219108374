import React from "react";
import { Fade, Zoom } from "react-reveal";
import { Helmet } from "react-helmet";

import Header from "../../includes/home/Header";
import styled from "styled-components";
import Footer from "../../includes/home/Footer";
import bg from "../../../assets/images/about/AboutBanner.jpg";
import tab_bg from "../../../assets/images/about/Banner_Tab.jpg";
import mob_bg from "../../../assets/images/about/Banner_Mob.jpg";
import aboutImg from "../../../assets/images/about/Aboutimage.jpg";
import grp from "../../../assets/images/about/grp.jpg";

function AboutPage() {
  return (
    <>
      <Helmet>
        <title>About English Cafe | English Training Centre in Calicut</title>
        <meta
          name="description"
          content="At English Cafe, we welcome you to boost your confidence and communication skills through our expertly crafted lessons. Join us now!"
        ></meta>
      </Helmet>
      <Container>
        <Banner>
          <Header />
          <Zoom>
            <Text>About</Text>
          </Zoom>
        </Banner>
        <Fade bottom>
          <Content>
            <Wrapper>
              <SubTitle>About</SubTitle>
              <CoverTop>
                <LeftSection>
                  <TitleText>English Cafe Stands Out Because...?</TitleText>
                  <Para>
                    English Cafe has quickly become one of the primary hubs for
                    students of all backgrounds and walks of life. Our unique
                    approach to English education involves curating learning
                    methods that fit the habits of our students. We have
                    realized that placing students in an ideal learning
                    environment enables them to absorb knowledge effectively.
                    That is why our lessons have become swift and simple for
                    students to grasp and retain in the long-run. Our
                    student-centered approach has yielded tremendous and lasting
                    results and we intend to keep at it, one student at a time.
                  </Para>
                  <Para className="wepave">
                    There is this feeling amongst students that English is a
                    complicated and terrifying language to master. That does not
                    have to be the case, and we make sure that our students
                    understand that. We invest time into dispelling
                    misconceptions and empowering our students into braving
                    their fears and improving their skills. Challenges make a
                    person strong, and that is part of our philosophy here at
                    English Cafe.
                  </Para>
                </LeftSection>
                <RightSection>
                  <img src={aboutImg} alt="image" />
                </RightSection>
                <PhotosMob>
                  <ImgContainer>
                    <img src={grp} alt="img" />
                  </ImgContainer>
                </PhotosMob>
              </CoverTop>
              <Para className="wepavetab">
                We don’t claim to make you Tharoor or Shakespeare in a span of 2
                months. What we can promise you is a strong foothold from which
                you can begin your journey into mastering the English language
                with ease.
              </Para>
              <Para>
                Whether you are a student, a working professional, employee,
                homemaker, entrepreneur or belong to any other walk of life;
                English Cafe welcomes you aboard our Language-learning vessel
                with open arms. Our experienced trainers work hard to construct
                lesson plans that are intended to build your confidence and
                communication skills simultaneously. As the English language
                undergoes various changes with the times, our trainers vow to
                stay vigilant to provide comprehensive and updated lessons for
                students to take to heart.
              </Para>
              <Para>
                With our dedication to excellence, we intend to assure each and
                every student that walks alongside us that they will be able to
                strut, then run and soar with their command over the English
                Language.
              </Para>
              <Photos>
                <ImgContainer>
                  <img src={grp} alt="img" />
                </ImgContainer>
              </Photos>
            </Wrapper>
          </Content>
        </Fade>
        <CallBanner>
          <span>Our Call Center :</span>
          <a href="tel:+91 7907139138">+91 7907139138</a>
        </CallBanner>
      </Container>
      <Footer />
    </>
  );
}

export default AboutPage;

const Container = styled.div``;
const TitleText = styled.h1`
  color: var(--blue);
  font-family: "mont-bold";
  @media all and (max-width: 480px) {
    font-size: 22px;
  }
`;
const Wrapper = styled.div`
  width: 70%;
  margin: 0 auto;
  @media all and (max-width: 1440px) {
    width: 80%;
  }
  @media all and (max-width: 768px) {
    flex-direction: column;
  }
`;
const Banner = styled.div`
  background: url(${bg});
  height: 62vh;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  @media all and (max-width: 1560px) {
    background-position: center;
  }
  @media all and (max-width: 1280px) {
    background-position: 30%;
  }
  @media all and (max-width: 768px) {
    background: url(${tab_bg});
  }
  @media all and (max-width: 480px) {
    background: url(${mob_bg});
    background-position: center;
    background-size: contain;
  }
`;
const Content = styled.div`
  padding: 40px 0;
`;
const Text = styled.h1`
  color: #fff;
  font-family: mont-medium;
  font-size: 50px;
  margin-left: 350px;
  margin-top: 150px;
  @media all and (max-width: 768px) {
    display: none;
  }
`;
const SubTitle = styled.h4`
  color: var(--blue);
  border-bottom: 1px solid var(--blue);
  padding-bottom: 10px;
  display: inline-block;
  font-family: "mont-medium";
  margin-bottom: 60px;
  @media all and (max-width: 480px) {
    margin-bottom: 10px;
  }
`;
const CallBanner = styled.div`
  background-color: #f5f4f5;
  padding: 30px 0;
  text-align: center;
  span {
    color: #d92119;
    font-size: 30px;
    @media all and (max-width: 480px) {
      font-size: 18px;
    }
  }
  a {
    margin-left: 10px;
    color: var(--blue);
    font-family: "mont-bold";
    text-decoration: none;
    font-size: 35px;
    @media all and (max-width: 480px) {
      font-size: 20px;
    }
  }
  @media all and (max-width: 480px) {
    padding: 20px 0;
  }
`;

const Para = styled.p`
  font-size: 16px;
  line-height: 32px;
  &.wepave {
    @media all and (max-width: 1280px) {
      display: none;
    }
  }
  &.wepavetab {
    display: none;
    @media all and (max-width: 1280px) {
      display: block;
    }
  }
  @media all and (max-width: 1700px) {
    font-size: 14px;
  }
`;
const LeftSection = styled.div`
  width: 50%;
  @media all and (max-width: 768px) {
    width: 100%;
  }
`;
const CoverTop = styled.div`
  display: flex;
  @media all and (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;
const RightSection = styled.div`
  width: 50%;
  @media all and (max-width: 768px) {
    width: 100%;
  }
  @media all and (max-width: 480px) {
    display: none;
  }
`;
const Photos = styled.div`
  margin-top: 30px;
  width: 94%;
  @media all and (max-width: 480px) {
    display: none;
  }
`;
const PhotosMob = styled.div`
  display: none;
  margin-top: 30px;
  width: 100%;
  @media all and (max-width: 480px) {
    display: block;
    margin-top: 0;
  }
`;
const ImgContainer = styled.div`
  width: 80%;
  margin: 0 auto;
  @media all and (max-width: 480px) {
    width: 100%;
  }
`;
