import React, { useContext, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { Context } from "../../context/store";

//imports
import AppRouter from "./AppRouter";
import AuthRoute from "../routes/AuthRoute";
import AuthRouter from "./AuthRouter";
import PrivateRoute from "../routes/PrivateRoute";
import DashboardRouter from "./DashboardRouter";
import PageLoader from "../../includes/loader/PageLoader";

function MainRouter() {
  const {
    dispatch,
    state: { user_data },
  } = useContext(Context);

  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    let user_data = localStorage.getItem("user_data");
    user_data = JSON.parse(user_data);
    dispatch({ type: "UPDATE_USER_DATA", user_data: user_data });
    setTimeout(() => setLoading(false), 1100);
  }, []);

  return isLoading ? (
    <PageLoader />
  ) : (
    <Routes>
      <Route
        path="dashboard/auth/*"
        element={
          <AuthRoute>
            <AuthRouter />
          </AuthRoute>
        }
      />
      <Route path="/*" element={<AppRouter />} />
      <Route
        path="/dashboard/*"
        element={
          <PrivateRoute>
            <DashboardRouter />
          </PrivateRoute>
        }
      />
    </Routes>
  );
}

export default MainRouter;
