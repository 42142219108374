import React, { useEffect, useState } from "react";
import { Fade, Zoom } from "react-reveal";
import api from "../../general/api";
import { Helmet } from "react-helmet";

import styled from "styled-components";
import Header from "../../includes/home/Header";
import bg from "../../../assets/images/achievements/AchievementsBanner.jpg";
import tab_bg from "../../../assets/images/achievements/BannerTab.jpg";
import mob_bg from "../../../assets/images/achievements/BannerMob.jpg";
import cure from "../../../assets/images/achievements/Asset 14.svg";
import Footer from "../../includes/home/Footer";
import { accountsConfig } from "../../../AxiosConfig";

function Achievements() {
  const [getAchievements, setAchievements] = useState([]);
  const [displayedItems, setDisplayedItems] = useState(4);

  const showMoreAchievements = () => {
    setDisplayedItems(getAchievements.length);
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  useEffect(() => {
    accountsConfig.get("company_profile/achievements/").then((res) => {
      const Data = res.data.app_data.data;
      if (res.data.app_data.StatusCode == 6000) {
        setAchievements(Data);
      }
    });
  }, []);
  return (
    <>
      <Helmet>
        <title>Our Achievements | English Cafe</title>
        <meta
          name="description"
          content="Explore our Achievements page to see the milestones and successes that make our English Training Academy a top choice for language learning."
        />
        <meta name="keywords" content="React, Helmet, Metadata" />
      </Helmet>
      <Container>
        <Banner>
          <Header />
          <Zoom>
            <Text>Achievements</Text>
          </Zoom>
        </Banner>
        <Fade bottom>
          <Content>
            <Wrapper>
              <LeftSection>
                <SubTitle>Achievements</SubTitle>
                <Title>Our Accolades to Date</Title>
                <Description>
                  We're over the moon and grateful with all the recognitions and
                  accolades that have come our way. We're still counting!
                </Description>
              </LeftSection>
              <Bottom>
                {getAchievements.map((x, index) => (
                  <Card key={index}>
                    <Curve>
                      <img src={cure} alt="->" />
                    </Curve>
                    <ImageContainer>
                      <img src={x.image} alt="award" />
                    </ImageContainer>
                    <TextContainer>{x.title}</TextContainer>
                  </Card>
                ))}
              </Bottom>
            </Wrapper>
          </Content>
        </Fade>
        <CallBanner>
          <span>Our Call Center :</span>
          <a href="tel:+91 7907139138">+91 7907139138</a>
        </CallBanner>
        <Footer />
      </Container>
    </>
  );
}

export default Achievements;

const Container = styled.div``;
const Card = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  margin-bottom: 30px;
  @media all and (max-width: 480px) {
    flex-direction: column;
  }
`;
const Curve = styled.div`
  width: 12%;
  @media all and (max-width: 1080px) {
    display: none;
  }
`;
const ImageContainer = styled.div`
  width: 33%;
  @media all and (max-width: 1080px) {
    width: 75%;
  }
  @media all and (max-width: 480px) {
    width: 100%;
    margin: 0 auto;
  }
`;
const TextContainer = styled.div`
  background-color: #f4f5f5;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 0 80px 80px 0;
  padding-left: 50px;
  box-sizing: border-box;
  padding: 20px 40px;
  @media all and (max-width: 1080px) {
    padding: 40px;
  }
  @media all and (max-width: 480px) {
    border-radius: 4px;
    padding: 20px;
  }
`;
const Wrapper = styled.div`
  width: 70%;
  margin: 0 auto;
  @media all and (max-width: 1440px) {
    width: 80%;
  }
`;
const Banner = styled.div`
  background: url(${bg});
  height: 62vh;
  background-repeat: no-repeat;
  display: flex;
  background-size: cover;
  justify-content: center;
  align-items: center;
  @media all and (max-width: 1560px) {
    background-position: center;
  }
  @media all and (max-width: 1280px) {
    background-position: 30%;
  }
  @media all and (max-width: 768px) {
    background: url(${tab_bg});
  }
  @media all and (max-width: 480px) {
    background: url(${mob_bg});
    background-position: center;
    background-size: contain;
  }
`;
const Content = styled.div`
  padding: 40px 0;
`;
const Text = styled.h1`
  color: #fff;
  font-family: mont-medium;
  font-size: 50px;
  margin-left: 350px;
  margin-top: 150px;
  @media all and (max-width: 768px) {
    display: none;
  }
`;
const LeftSection = styled.div`
  width: 100%;
  /* height: 58px 30px; */
  /* min-height: 485px; */
  /* padding: 60px 30px; */
  box-sizing: border-box;
  @media all and (max-width: 1080px) {
    width: 100%;
    padding: 60px 30px 30px 30px;
  }
  @media all and (max-width: 400px) {
    padding: 0 0px;
  }
`;
const SubTitle = styled.h4`
  color: var(--blue);
  border-bottom: 1px solid var(--blue);
  padding-bottom: 10px;
  display: inline-block;
  font-family: "mont-medium";
  margin-bottom: 60px;
  @media all and (max-width: 400px) {
    margin-bottom: 10px;
  }
`;
const Description = styled.p`
  font-size: 16px;
  line-height: 30px;
`;

const Title = styled.h2`
  color: var(--blue);
  font-family: "mont-bold";
  font-size: 30px;
`;
const CallBanner = styled.div`
  background-color: #f5f4f5;
  padding: 30px 0;
  text-align: center;
  span {
    color: #d92119;
    font-size: 30px;
    @media all and (max-width: 480px) {
      font-size: 18px;
    }
  }
  a {
    margin-left: 10px;
    color: var(--blue);
    font-family: "mont-bold";
    text-decoration: none;
    font-size: 35px;
    @media all and (max-width: 480px) {
      font-size: 20px;
    }
  }
  @media all and (max-width: 480px) {
    padding: 20px 0;
  }
`;
const Bottom = styled.div``;
