import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../context/store";
import { Link, useNavigate } from "react-router-dom";
import api from "../../general/api";
import { Bounce } from "react-reveal";

import styled from "styled-components";
import ButtonLoader from "../../includes/loader/ButtonLoader";
import { accountsConfig } from "../../../AxiosConfig";

function Login() {
  const [show, setShow] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [errorMsg, setErrorMsg] = useState("");

  const {
    dispatch,
    state: { user_data },
  } = useContext(Context);

  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      setErrorMsg("");
    }, 4000);
  }, [errorMsg]);

  const submitHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    accountsConfig
      .post("accounts/login-student-profile/", {
        username: username,
        password: password,
      })
      .then((response) => {
        console.log(response);
        const { StatusCode, data } = response.data.app_data;
        if (StatusCode === 6000) {
          setLoading(false);
          dispatch({
            type: "UPDATE_USER_DATA",
            user_data: {
              isLogin: true,
              access_token: data.access.access,
              refresh_token: data.access.refresh,
              isModal: false,
              student_id: data.student_id,
            },
          });
          navigate("/dashboard");
        } else {
          setErrorMsg(data.message);
          setLoading(false);
        }
      });
  };
  const enterFunction = (e) => {
    if (username == "") {
      setErrorMsg("Please fill out all fields");
    }
    if (e.key === "Enter") {
      submitHandler(e);
    }
  };

  return (
    <MainContainer>
      <Overlay></Overlay>

      <BackContainer>
        <CloseIcon to="/">
          <i className="ri-close-fill"></i>
        </CloseIcon>
        <Modal type="textarea">
          <Container>
            <Form onSubmit={(e) => submitHandler(e)}>
              <Title>LOGIN</Title>
              <Desc>Please enter your login and password</Desc>
              <CoverInput>
                <Label>Username</Label>
                <Input
                  type="text"
                  placeholder="username"
                  onKeyDown={(e) => enterFunction(e)}
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  required
                />
              </CoverInput>
              <CoverInput>
                <Label>Password</Label>
                <Input
                  type={show ? "text" : "password"}
                  placeholder="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  onKeyDown={(e) => enterFunction(e)}
                />
                <Icon onClick={() => setShow(!show)}>
                  {show ? (
                    <i className="ri-eye-off-line"></i>
                  ) : (
                    <i className="ri-eye-line"></i>
                  )}
                </Icon>
              </CoverInput>
              {/* {isLoading ? (
                <ButtonIn>
                  <ButtonLoader />
                </ButtonIn>
              ) : ( */}
              <CoverButton>
                <Button type="submit" value="Submit" />
              </CoverButton>
              {/* )} */}
              {errorMsg && (
                <Bounce>
                  <Error>
                    {errorMsg == "Student profile not exists"
                      ? "The student profile does not exist."
                      : errorMsg ==
                        "username - This field may not be blank. | password - This field is required."
                      ? "Please fill out all fields"
                      : errorMsg == "password - This field is required."
                      ? "Please enter your password"
                      : errorMsg == "username - This field may not be blank."
                      ? "Please enter your username"
                      : ""}
                  </Error>
                </Bounce>
              )}
            </Form>
          </Container>
        </Modal>
      </BackContainer>
    </MainContainer>
  );
}
export default Login;

const BackContainer = styled.div`
  position: fixed;
  transition: 0.3s;
  transform: scale(1, 1);
  width: 100%;
  margin: 0 auto;
  right: 0;
  height: 100vh;
  z-index: 1000;
  left: 0;
  top: 0px;
`;
const Overlay = styled.div`
  background: rgba(21, 62, 126, 0.927);
  backdrop-filter: blur(2px);
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0px;
  width: 100%;
  min-height: 100vh;
  max-height: 100vh;
  filter: blur(1px);
`;
const Modal = styled.div`
  width: 90%;
  max-width: 350px;
  margin: 0 auto;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  transition: 0.5s;
  z-index: 101;
  border-radius: 5px;
  /* background: #fff; */
  @media all and (max-width: 1280px) {
    width: 520px;
  }
  @media all and (max-width: 1080px) {
    width: 500px;
  }
  @media all and (max-width: 980px) {
    width: 650px;
  }
  @media all and (max-width: 768px) {
    width: 480px;
  }
  @media all and (max-width: 640px) {
    width: 400px;
  }
  @media all and (max-width: 480px) {
    width: 340px;
  }
  @media all and (max-width: 360px) {
    width: 300px;
  }
`;
const MainContainer = styled.div``;
const CoverButton = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;
const Error = styled.h4`
  color: #fff;
  margin-top: 20px;
`;

const Container = styled.div`
  height: 100vh;
  width: 100%;
  /* background-color: var(--blue); */
  backdrop-filter: blur;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Form = styled.form`
  width: 100%;
  text-align: center;
`;
const Title = styled.h1`
  font-size: 40px;
  text-align: center;
  margin-bottom: 30px;
`;
const Desc = styled.p`
  margin-bottom: 30px;
  font-size: 14px;
  text-align: center;
`;
const CoverInput = styled.div`
  margin-bottom: 20px;
  position: relative;
`;
const Input = styled.input`
  background: transparent;
  outline: none;
  outline-style: none;
  border: 1px solid #24c4fc;
  height: 50px;
  box-sizing: border-box;
  padding-left: 20px;
  color: #fff;
  width: 100%;
  border-radius: 30px;
  &::placeholder {
    color: #9ccafe;
  }
`;
const Label = styled.h5`
  margin-bottom: 5px;
  margin-left: 20px;
  text-align: left;
  @media all and (max-width: 400px) {
    letter-spacing: 1px;
  }
`;
const Icon = styled.div`
  color: #24c4fc;
  position: absolute;
  right: 20px;
  top: 40px;
  cursor: pointer;
  @media all and (max-width: 400px) {
    right: 20px;
    top: 38px;
  }
`;
const ButtonIn = styled.div`
  background-color: var(--red);
  width: 100px;
  height: 40px;
  font-size: 16px;
  border-radius: 30px;
  color: #fff;
  font-family: "mont-regular";
  cursor: pointer;
  text-decoration: none;
  border: none;
  @media all and (max-width: 400px) {
    font-size: 16px;
    padding: 8px 26px;
  }
`;
const Button = styled.input`
  background-color: var(--red);
  width: 100px;
  height: 40px;
  font-size: 16px;
  border-radius: 30px;
  color: #fff;
  font-family: "mont-regular";
  cursor: pointer;
  text-decoration: none;
  border: none;
  @media all and (max-width: 400px) {
    font-size: 16px;
  }
`;

const CloseIcon = styled(Link)`
  color: #fff;
  font-size: 40px;
  text-decoration: none;
  cursor: pointer;
  position: absolute;
  z-index: 10000;
  right: 100px;
  top: 50px;
  @media all and (max-width: 480px) {
    right: 30px;
    top: 20px;
  }
`;
