import React, { useState, useEffect, useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import first from '../../../../src/assets/images/newImages/first.jpg';
import second from '../../../../src/assets/images/newImages/second.jpg';
import third from '../../../../src/assets/images/newImages/third.jpg';
import fourth from '../../../../src/assets/images/newImages/fourth.jpg';
import fifth from '../../../../src/assets/images/newImages/fifth.jpg';
import sixth from '../../../../src/assets/images/newImages/sixth.jpg';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

function Service() {
    const [visibleItems, setVisibleItems] = useState([]);
    const cardRefs = useRef([]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setVisibleItems((prev) => [...prev, entry.target.dataset.index]);
                    }
                });
            },
            { threshold: 0.1}
        );
        cardRefs.current.forEach((ref) => { if (ref) observer.observe(ref); });
        return () => { cardRefs.current.forEach((ref) => { if (ref) observer.unobserve(ref); }); };
    }, []);

    const data = [
        {
            image: first,
            title: "Enhance Your English Skills with English Cafe",
            description: `<span>We are committed to help you achieve fluency and confidence in speaking English. Our carefully curated courses cater to all proficiency levels, from beginners to advanced learners. With a strong focus on practical application, we offer a range of programs designed to meet your specific language needs. This <a href="https://englishcafeonline.com/">English language course online<a/> Courses offer a flexible and interactive approach. Learn from anywhere, at your own pace. Join us to improve your English through engaging lessons, expert guidance, and innovative tools.</span>`
        },
        {
            image: second,
            title: "Spoken English Classes",
            description: `<span>Improve your communication with our <a href="https://englishcafeonline.com/">Spoken English Classes in Calicut</a>. These classes focus on enhancing your fluency, pronunciation, and confidence in everyday conversations. Through interactive sessions, you will practice real-life scenarios and receive personalized feedback from our experienced instructors. The English Speaking Courses help you practice speaking with confidence in various contexts, from casual conversations to professional settings.</span>`
        },
        {
            image: third,
            title: "English Grammar Courses",
            description: `<span>Master the fundamentals of the language with our English Grammar Course in Calicut. This course dives deep into essential grammar rules, sentence structures, and usage. With clear explanations and practical exercises, you will gain the confidence to write and speak English accurately. Suitable for learners who want to build a strong grammatical foundation.</span>`
        },
        {
            image: fourth,
            title: "IELTS Coaching",
            description: `<span>Our <a href="https://englishcafeonline.com/">IELTS Coaching<a/> classes are designed to crack exam with desired scores. With a focus on the four key areas—listening, reading, writing, and speaking—this course offers comprehensive preparation. Our experienced trainers provide personalized strategies, practice tests, and detailed feedback to ensure you're ready for the exam.</span>`
        },
        {
            image: fifth,
            title: "English Learning App",
            description: `<span>Learn on the go with the <a href="https://englishcafeonline.com/">English Learning App</a> from English Cafe. This app offers access to all our courses, including video lessons, quizzes, and interactive exercises. Track your progress, study at your own pace, and access course materials anytime, anywhere. The app is designed to complement our online courses and enhance your learning experience.</span>`
        },
        {
            image: sixth,
            title: "Start Your English Learning Journey Today",
            description: `<span>Our courses, whether it's mastering grammar or improving speaking skills, are designed to guide you every step of the career pathway. With flexible learning options and expert support, you can learn at your own pace and achieve your goals.</span>`
        },
    ];

    return (
        <Container>
            <Wrapper>
                <Sub>Learning Levels</Sub>
                <Heading>What We Do</Heading>
                {data.map((item, index) => (
                    <Card 
                        key={index} 
                        reverse={index % 2 !== 0}
                        ref={(el) => (cardRefs.current[index] = el)}
                        data-index={index}
                        isVisible={visibleItems.includes(index.toString())}
                    >
                        <ImageContainer>
                            <StyledImage src={item.image} alt={item.title} />
                        </ImageContainer>
                        <Content>
                            <Title>{item.title}</Title>
                            <Description dangerouslySetInnerHTML={{ __html: item.description }} />
                        </Content>
                    </Card>
                ))}
            </Wrapper>
        </Container>
    );
}

export default Service;

const fadeInUp = keyframes`
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
`;

const Container = styled.div`
  position: relative;
  background-color: rgb(18, 56, 118);
  padding: 80px 0;
  overflow-x:hidden;
  &::before { content: ""; position: absolute; top: 0; left: 0; width: 100vw; height: 100%; background-color: rgb(18, 56, 118); z-index: -1; }
`;

const Wrapper = styled.div`
  position: relative;
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

const Heading = styled.h1`
  font-family: "mont-bold";
  font-size: 30px;
  margin-top: 20px;
  color: white;
  margin-bottom: 40px;
  padding: 0 10px;

  @media (max-width: 768px) {
    font-size: 24px;
    margin-bottom: 30px;
    padding: 0;
  }
`;

const Sub = styled.h4`
  color: white;
  padding: 10px 10px 20px;
  border-bottom: 1px solid white;
  width: fit-content;

  @media (max-width: 768px) {
    padding: 10px 0;
  }
`;

const Card = styled.div`
  display: flex;
  flex-direction: ${({ reverse }) => (reverse ? 'row-reverse' : 'row')};
  gap: 10px;
  background-color: white;
  padding: 10px;
  border-radius: 34px;
  width: 100%;
  align-items: center;
  opacity: 0;
  transform: translateY(20px);
  animation: ${({ isVisible }) => isVisible ? fadeInUp : 'none'} 0.6s ease-out forwards;

  @media (max-width: 768px) {
    flex-direction: column;
    border-radius: 20px;

  }
`;

const ImageContainer = styled.div`
  flex: 1;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 24px;

  @media (max-width: 768px) {
    height: 150px;
    border-radius: 10px;
  }
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Content = styled.div`
  flex: 2;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media (max-width: 768px) {
    padding: 0;
  }
`;

const Title = styled.h3`
  font-family: "mont-bold";
  font-size: 24px;
  color: rgb(18, 56, 118);
  margin-bottom: 10px;
  padding: 0 10px;

  @media (max-width: 768px) {
    font-size: 20px;
    padding: 0;
  }
`;

const Description = styled.p`
  font-family: "mont-regular";
  font-size: 16px;
  color: rgb(80, 80, 80);
  line-height: 22px;
  margin: 0;
  padding: 0 10px;

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 0;
  }
`;
