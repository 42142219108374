import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import logo from "../../../assets/images/footer/logo.png";
import banner from "../../../assets/images/whatsappform/banner.png";
import send from "../../../assets/images/whatsappform/send.svg";
import contentImg from "../../../assets/images/whatsappform/text.svg";
import { Context } from "../../context/store";

function WhatsappEnquiryModal({ isModal, setModal }) {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState("");
  const [delta, setDelta] = useState(300 - Math.random() * 200);
  const [index, setIndex] = useState(1);

  //running text
  const toRotate = ["Hi..", "I would like to know more. "];
  const period = 2000;

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => {
      clearInterval(ticker);
    };
  }, [text]);

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting
      ? fullText.substring(0, text.length - 1)
      : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta((prevDelta) => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setIndex((prevIndex) => prevIndex - 1);
      setDelta(period);
    } else if (isDeleting && updatedText === "") {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setIndex(1);
      setDelta(100);
    } else {
      setIndex((prevIndex) => prevIndex + 1);
    }
  };

  return (
    <MainContainer>
      {isModal ? <Overlay onClick={() => setModal()}></Overlay> : ""}

      <BackContainer style={{ transform: isModal && "scale(1,1)" }}>
        <Modal type="textarea">
          <Container>
            <WhatsappDiv>
              <CloseIcon onClick={() => setModal()}>
                <i className="ri-close-fill"></i>
              </CloseIcon>
              <LeftContainer>
                <img src={banner} alt="banner" />
              </LeftContainer>
              <RightContainer>
                <Logo>
                  <img src={logo} alt="logo" />
                </Logo>
                <MalayalmText>
                  <img src={contentImg} alt="malayalam text" />
                </MalayalmText>
                <Chat>
                  <TextDiv data-rotate="[Hi]">{text}</TextDiv>
                  <Send href="https://api.whatsapp.com/send?phone=7907139138&text=Hi, I would like to know more.">
                    <img src={send} alt="img" />
                  </Send>
                </Chat>
              </RightContainer>
            </WhatsappDiv>
          </Container>
        </Modal>
      </BackContainer>
    </MainContainer>
  );
}
export default WhatsappEnquiryModal;

const BackContainer = styled.div`
  position: fixed;
  transition: 0.3s;
  transform: scale(0, 0);
  width: 100%;
  margin: 0 auto;
  right: 0;
  height: 100vh;
  z-index: 1000;
  left: 0;
  top: 0px;
`;
const Overlay = styled.div`
  /* background: rgba(21, 61, 126, 0.489); */
  backdrop-filter: blur(2px);
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0px;
  width: 100%;
  min-height: 100vh;
  max-height: 100vh;
  filter: blur(1px);
`;
const Modal = styled.div`
  width: 75%;
  /* max-width: 350px; */
  margin: 0 auto;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  transition: 0.5s;
  z-index: 101;
  border-radius: 5px;
  /* background: #fff; */
`;
const MainContainer = styled.div``;
const CloseIcon = styled.div`
  position: absolute;
  cursor: pointer;
  right: 30px;
  top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 26px;
  color: rgb(255, 255, 255);
  border: 1px solid rgb(255, 255, 255);
  width: 30px;
  border-radius: 10px;
  height: 30px;
`;

const Container = styled.div`
  height: 100vh;
  width: 100%;
  /* background-color: var(--blue); */
  backdrop-filter: blur;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const WhatsappDiv = styled.div`
  display: flex;
  position: relative;
  background-color: #133976;
  padding: 80px 40px;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid rgb(59, 124, 206);
  /* align-items: center; */
  @media all and (max-width: 400px) {
    padding: 60px 40px 0;
  }
`;
const LeftContainer = styled.div`
  width: 40%;
  margin-right: 50px;
  @media all and (max-width: 768px) {
    display: none;
  }
`;
const RightContainer = styled.div`
  width: 35%;
  @media all and (max-width: 768px) {
    width: 100%;
  }
  @media all and (max-width: 400px) {
    min-height: 50vh;
  }
`;
const Logo = styled.div`
  margin-bottom: 50px;
  width: 45%;
  @media all and (max-width: 400px) {
    width: 55%;
    margin-bottom: 20px;
  }
`;
const MalayalmText = styled.div`
  margin-bottom: 30px;
`;
const Chat = styled.div`
  display: flex;
  border: 1px solid #3b7cce;
  padding: 10px;
  border-radius: 20px;
  align-items: center;
`;
const TextDiv = styled.div`
  background-color: #3b7cce;
  padding: 15px;
  border-radius: 10px;
  margin-right: 10px;
  font-size: 14px;
  width: 90%;
  box-sizing: border-box;
  height: 40px;
  @media all and (max-width: 400px) {
    height: unset;
  }
`;
const Send = styled.a`
  width: 35px;
  cursor: pointer;
`;
