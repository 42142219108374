import React from "react";
import { Route, Routes } from "react-router-dom";

//imports
import Dashboard from "../../screens/dashboard/Dashboard";
import Profile from "../../screens/profile/Profile";

function DashboardRouter() {
  return (
    <Routes>
      <Route path="/" element={<Dashboard />} />
      <Route path="/profile/" element={<Profile />} />
    </Routes>
  );
}

export default DashboardRouter;
