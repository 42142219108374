import React from "react";
import { Helmet } from "react-helmet";
import { Outlet } from "react-router-dom";

import Footer from "../includes/home/Footer";
import Spotlight from "../includes/home/Spotlight";
import About from "../includes/home/About";
import Learning from "../includes/home/Learning";
import Provide from "../includes/home/Provide";
import GetInTouch from "../includes/home/GetInTouch";
import Service from "../includes/home/Service";
import Faq from "../includes/home/Faq";

function Home({ isModal, closeFunction, number }) {
  return (
    <>
      <Helmet>
        <title>English Cafe | Learn English online | IELTS Coaching | Calicut</title>
        <meta
          name="description"
          content="Enhance your language skills at our English training center and excel in IELTS with our top-notch online courses. Join us for success!"
        ></meta>
      </Helmet>

      <Spotlight
        isModal={isModal}
        closeFunction={closeFunction}
        number={number}
      />

      <About />
      <Learning />
      <Service/>
      <Provide />
      <Faq/>
      <GetInTouch />
      <Footer />
      <Outlet />
    </>
  );
}

export default Home;
