import React, { useEffect, useState } from "react";
import styled from "styled-components";
import $ from "jquery";
import bg from "../../../assets/images/form/bg.svg";
import api from "../../general/api";
import { Link } from "react-router-dom";
import { accountsConfig } from "../../../AxiosConfig";

function EnquiryForm({ isModal, setModal, id, title, description }) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [file, setFile] = useState();

  const formData = new FormData();
  formData.append("job", id);
  formData.append("name", name);
  formData.append("phone", phone);
  formData.append("email", email);
  formData.append("cv", file);
  const handleFormSubmit = (e) => {
    e.preventDefault();
    accountsConfig
      .post("company_profile/create-career-enquiry/", formData)
      .then((response) => {
        const { StatusCode, data } = response.data.app_data;
        if (StatusCode === 6000) {
          setModal(false);
          setName("");
          setPhone("");
          setEmail("");
          setFile(null);
        } else {
        }
      })
      .catch((error) => {
        // setError(true)
      });
  };
  return (
    <MainContainer>
      {isModal ? <Overlay onClick={() => setModal(false)}></Overlay> : ""}

      <BackContainer style={{ transform: isModal && "scale(1,1)" }}>
        <Modal type="textarea">
          <Container>
            <EnqForm>
              <CloseIcon onClick={() => setModal(false)}>
                <i className="ri-close-fill"></i>
              </CloseIcon>
              <CoverContainer>
                <img src={bg} alt="" />
              </CoverContainer>
              <LeftSec>
                <Tittle>{title}</Tittle>
                <Para>{description}</Para>
              </LeftSec>
              <Form onSubmit={(e) => handleFormSubmit(e)}>
                <CoverInput>
                  <Label>Name</Label>
                  <Input
                    type="text"
                    placeholder="Your name"
                    required
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </CoverInput>
                <CoverInput>
                  <Label>Email</Label>
                  <Input
                    type="email"
                    placeholder="Your email id"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </CoverInput>
                <CoverInput>
                  <Label>Phone</Label>
                  <Input
                    type="number"
                    placeholder="Your Phone number"
                    required
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </CoverInput>
                <Bottom>
                  <Cv>
                    <span>Upload your CV</span>
                    <UploadButton>
                      <span>
                        {file && file?.name.length > 0 ? "Uploaded" : "Upload"}
                        {file && file?.name.length > 0 && (
                          <i class="ri-check-double-line"></i>
                        )}
                      </span>
                    </UploadButton>
                    <InputFile
                      type="file"
                      onChange={(e) => setFile(e.target.files[0])}
                    />
                  </Cv>
                  <Button type="submit" value="Submit" />
                </Bottom>
              </Form>
            </EnqForm>
          </Container>
        </Modal>
      </BackContainer>
    </MainContainer>
  );
}
export default EnquiryForm;

const BackContainer = styled.div`
  position: fixed;
  transition: 0.3s;
  transform: scale(0, 0);
  width: 100%;
  margin: 0 auto;
  right: 0;
  height: 100vh;
  z-index: 1000;
  left: 0;
  top: 0px;
`;
const Overlay = styled.div`
  background: rgba(21, 62, 126, 0.927);
  backdrop-filter: blur(2px);
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0px;
  width: 100%;
  min-height: 100vh;
  max-height: 100vh;
  filter: blur(1px);
`;
const Modal = styled.div`
  width: 75%;
  /* max-width: 350px; */
  margin: 0 auto;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  transition: 0.5s;
  z-index: 101;
  border-radius: 5px;
  /* background: #fff; */
`;
const MainContainer = styled.div``;
const CloseIcon = styled.div`
  position: absolute;
  cursor: pointer;
  right: 30px;
  top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 26px;
  color: rgb(36, 196, 252);
  border: 1px solid rgb(36, 196, 252);
  width: 30px;
  border-radius: 10px;
  height: 30px;
`;

const Container = styled.div`
  height: 100vh;
  width: 100%;
  /* background-color: var(--blue); */
  backdrop-filter: blur;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Form = styled.form`
  width: 50%;
  text-align: center;
  @media all and (max-width: 1080px) {
    width: 100%;
  }
`;
const CoverInput = styled.div`
  margin-bottom: 20px;
  position: relative;
`;
const Input = styled.input`
  background: #fff;
  outline: none;
  outline-style: none;
  border: 1px solid #24c4fc;
  height: 50px;
  padding-left: 20px;
  box-sizing: border-box;
  color: #000;
  width: 100%;
  border-radius: 30px;
  &::placeholder {
    color: #4a4a4a;
  }
`;
const Label = styled.h5`
  margin-bottom: 5px;
  margin-left: 20px;
  text-align: left;
`;
const Button = styled.input`
  background-color: var(--red);
  width: fit-content;
  padding: 8px 24px;
  border-radius: 30px;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 20px;
  text-decoration: none;
  @media all and (max-width: 768px) {
    margin-top: 20px;
    font-size: 14px;
  }
`;
const LeftSec = styled.div`
  width: 50%;
  margin-right: 80px;
  @media all and (max-width: 1080px) {
    width: 100%;
    margin-right: 0;
  }
`;
const Tittle = styled.h2`
  color: var(--blue);
  font-family: "mont-medium";
  margin-bottom: 20px;
`;
const Bottom = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @media all and (max-width: 768px) {
    flex-direction: column;
  }
`;
const InputFile = styled.input`
  position: absolute;
  width: 133px;
  right: 0;
  opacity: 0;
`;
const Cv = styled.div`
  position: relative;
  align-items: center;
  display: flex;
  margin-right: 10px;
  span {
    font-size: 14px;
  }
`;
const Para = styled.p`
  line-height: 28px;

  @media all and (max-width: 768px) {
    font-size: 14px;
    line-height: 24px;
  }
  @media all and (max-width: 400px) {
    display: none;
  }
`;
const UploadButton = styled.div`
  border: 1px solid #24c4fc;
  padding: 10px;
  margin-left: 10px;
  border-radius: 20px;
  span {
    border: 1px dotted rgb(16, 16, 16);
    padding: 8px 30px;
    font-size: 14px;
    border-radius: 20px;
    background-color: rgb(227, 226, 227);
  }
`;
const CoverContainer = styled.div`
  position: absolute;
  width: 50%;
  left: 0;
`;
const EnqForm = styled.div`
  padding: 80px;
  position: relative;
  background-color: #f5f4f5;
  display: flex;
  box-sizing: border-box;
  color: #000;
  width: 100%;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  @media all and (max-width: 1080px) {
    flex-direction: column;
  }
  @media all and (max-width: 768px) {
    padding: 40px;
  }
  @media all and (max-width: 400px) {
    padding: 30px;
  }
`;
