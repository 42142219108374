import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import LottieCracker from "../loader/LottieCracker";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/dist/sweetalert2.css";

function SuccessModal({ isModal, setModal, isAlert, play }) {
  return (
    <Container className={isModal && "active"}>
      {play && <LottieCracker />}
    </Container>
  );
}

export default SuccessModal;

const Container = styled.div`
  position: fixed;
  transition: 0.3s;
  transform: scale(0, 0);
  width: 100%;
  height: 100vh;
  z-index: 1000;
  left: 0;
  top: 0px;
  &.active {
    transform: scale(1, 1);
    /* backdrop-filter: blur(4px); */
  }
`;
