import React, { useContext, useEffect, useRef, useState } from "react";
import { Fade, Zoom } from "react-reveal";
import { Helmet } from "react-helmet";
import { Context } from "../../context/store";
import { gallery_images } from "../../general/arrays";
import api from "../../general/api";

import styled from "styled-components";
import bg from "../../../assets/images/gallery/GalleryWebBanner.jpg";
import Header from "../../includes/home/Header";
import Slider from "react-slick";
import ReactPlayer from "react-player";
import thumb from "../../../assets/images/gallery/EC/Asset 11.png";
import { accountsConfig } from "../../../AxiosConfig";

function Gallery() {
  const [videoUrl, setVideoUrl] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [sliderAutoplay, setSliderAutoplay] = useState(true);
  const [selectedUrl, setSelectedUrl] = useState("");

  const {
    state: { user_data },
  } = useContext(Context);
  const settings = {
    slidesToShow: 1,
    autoplay: sliderAutoplay,
    slidesToScroll: 1,
    infinite: true,
    arrows: true,
    dots: false,
    responsive: [
      {
        breakpoint: 500,
        settings: {
          arrows: false,
        },
      },
    ],
    beforeChange: (current, next) => {
      // Pause the video when the slide changes
      if (current !== next) {
        setSliderAutoplay(false);
      }
    },
    afterChange: (current) => {
      // Update the current slide index
      setCurrentSlide(current);
    },
  };

  useEffect(() => {
    accountsConfig.get("company_profile/get-youtube-links/", {}).then((res) => {
      const StatusCode = res.data.app_data.StatusCode;
      const Data = res.data.app_data.data;
      if (StatusCode == 6000) {
        setVideoUrl(Data);
      } else {
      }
    });
  }, []);
  return (
    <>
      <Helmet>
        <title>Gallery | English Cafe</title>
        <meta
          name="description"
          content="Browse through our gallery to discover the vibrant moments and learning experiences at our English Training Academy."
        />
      </Helmet>
      <Container>
        <Banner>
          <Header />
          <Zoom>
            <Text>Gallery</Text>
          </Zoom>
        </Banner>
        <Fade bottom>
          <Content>
            <Wrapper className="wrapper">
              <GallerySection>
                <SubTitle>Gallery</SubTitle>
                <GalleryImages>
                  {gallery_images?.map((x, index) => (
                    <Gall key={index}>
                      <Top>
                        <SlotImages>
                          <img src={x.slot_one} alt="image" />
                        </SlotImages>
                        <SlotImages>
                          <img src={x.slot_two} alt="image" />
                        </SlotImages>
                      </Top>
                      <Bottom>
                        <SlotImage>
                          {x.slot_three && (
                            <img src={x.slot_three} alt="image" />
                          )}
                        </SlotImage>
                        <SlotBig>
                          {x.slot_four && <img src={x.slot_four} alt="image" />}
                        </SlotBig>
                      </Bottom>
                    </Gall>
                  ))}
                </GalleryImages>
              </GallerySection>
            </Wrapper>
          </Content>
        </Fade>
        <VideoSection>
          <Wrapper className="wrapper">
            <VideoPlayerSec>
              <Player>
                <ReactPlayer
                  url={selectedUrl ? selectedUrl : videoUrl[0]?.file_link}
                  controls
                  height="673px"
                  margin="150px 0 0 0"
                  width="100%"
                  className="videoplayer"
                />
              </Player>
              <ThumbNails>
                {videoUrl?.map((item, index) => (
                  <Thumb onClick={() => setSelectedUrl(item.file_link)}>
                    <img src={item.thumbnail} alt="thumbnail" />
                  </Thumb>
                ))}
              </ThumbNails>
            </VideoPlayerSec>
          </Wrapper>
        </VideoSection>
      </Container>
    </>
  );
}

export default Gallery;

const VideoPlayerSec = styled.div`
  width: 80%;
  margin: 0 auto;
  @media all and (max-width: 480px) {
    width: 100%;
  }
`;
const VideoSection = styled.div`
  padding: 80px 0;
  @media all and (max-width: 480px) {
    padding: 40px 0;
  }
`;
const Container = styled.div``;
const Player = styled.div`
  width: 100%;
  margin-bottom: 30px;
`;
const Banner = styled.div`
  background: url(${bg});
  height: 62vh;
  background-repeat: no-repeat;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @media all and (max-width: 1800px) {
    background-position: center;
  }
  @media all and (max-width: 1280px) {
    background-position: 30%;
  }
  @media all and (max-width: 768px) {
    justify-content: center;
    align-items: center;
  }
`;
const Text = styled.h1`
  color: #fff;
  font-family: mont-medium;
  font-size: 50px;
  margin-right: 400px;
  margin-top: -10px;
  @media all and (max-width: 1700px) {
    margin-right: 300px;
  }
  @media all and (max-width: 1440px) {
    margin-right: 200px;
  }
  @media all and (max-width: 768px) {
    margin-right: 0;
  }
  @media all and (max-width: 480px) {
    display: none;
  }
`;
const SubTitle = styled.h4`
  color: var(--blue);
  border-bottom: 1px solid var(--blue);
  padding-bottom: 10px;
  margin-left: 120px;
  display: inline-block;
  font-family: "mont-medium";
  margin-bottom: 30px;
  @media all and (max-width: 400px) {
    margin-bottom: 20px;
    margin-left: 0;
  }
`;
const Content = styled.div`
  background-color: #f4f5f5;
  padding-bottom: 80px;
  @media all and (max-width: 480px) {
    padding-bottom: 20px;
  }
`;
const Wrapper = styled.div``;
const GallerySection = styled.div`
  padding-top: 50px;
`;

const GalleryImages = styled.div``;
const Gall = styled.div``;
const Top = styled.div`
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-bottom: 15px;
`;
const SlotImages = styled.div``;
const Bottom = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
  gap: 15px;
`;
const SlotImage = styled.div``;
const SlotBig = styled.div``;
const ThumbNails = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
`;
const Thumb = styled.div`
  height: 200px;
  cursor: pointer;
  overflow: hidden;
`;
