import { BrowserRouter } from "react-router-dom";
import HelmetProvider from "react-helmet";
import { useEffect } from "react";

import Store from "./components/context/store";
import MainRouter from "./components/routing/router/MainRouter";

import "./App.css";
import "./assets/css/style.css";
import "remixicon/fonts/remixicon.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./assets/css/audio.css";
import "./assets/js/script.js";
import "sweetalert2/src/sweetalert2.scss";
import { api } from "./AxiosConfig.js";

function App() {
  return (
    // <HelmetProvider>
    <Store>
      <BrowserRouter>
        <MainRouter />
      </BrowserRouter>
    </Store>
    // </HelmetProvider>
  );
}

export default App;
