import React, { useState } from "react";
import styled from "styled-components";
import contact from "../../../assets/images/provide/Asset 2.png";
import { Fade } from "react-reveal";
import api from "../../general/api";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/dist/sweetalert2.css";
import ButtonLoader from "../loader/ButtonLoader";

function GetInTouch() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setLoading] = useState("");

  //sweet alert for success modal
  const showSuccessAlert = () => {
    Swal.fire({
      position: "center",
      icon: "success",
      title: "Success",
      showConfirmButton: false,
      // timer: 1500,
      iconColor: "#0e2d5e",
    });
  };

  //create enquiry
  const submitHandler = (e) => {
    setLoading(true);
    e.preventDefault();
    api
      .post("company_profile/create-enquiry-email/", {
        name: name,
        email: email,
        phone: phone,
        message: message,
      })
      .then((response) => {
        const { StatusCode, data } = response.data.app_data;
        if (StatusCode === 6000) {
          // Swal.isVisible();
          setLoading(false);
          setName("");
          setEmail("");
          setPhone("");
          setMessage("");
          showSuccessAlert();
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        // setError(true)
      });
  };
  const handleInputChange = (event) => {
    if (/[0-9!@#$%^&*()_+{}\[\]:;<>,.?~\\/"'-=`]/.test(event.target.value)) {
      event.preventDefault();
    } else {
      setName(event.target.value);
    }
  };

  return (
    <Container id="enquiry">
      <Fade bottom delay={400}>
        <Wrapper className="wrapper">
          <Content>
            <Left>
              <Sub>Get in Touch!</Sub>
              <Title>
                You can reach us <br /> by phone, Email <br /> or Posting.
              </Title>
              <Address>
                <Heading>Office Address:</Heading>
                <Span>
                  The training hub, <br /> Opposite APCO Honda, <br />{" "}
                  Mini-bypass road, Puthiyara, Calicut.
                </Span>
              </Address>
            </Left>
            <Right>
              <ImageContainer>
                <img src={contact} alt="image" />
              </ImageContainer>
              <FormSection onSubmit={(e) => submitHandler(e)}>
                <FormTitle>Have a Question? Please write to us here.</FormTitle>
                <Input
                  type="text"
                  placeholder="Full Name:"
                  value={name}
                  onChange={(e) => handleInputChange(e)}
                  required
                />
                <Input
                  type="email"
                  placeholder="Email Address:"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <Input
                  type="number"
                  required
                  placeholder="Phone Number:"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
                <TextArea
                  required
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                ></TextArea>
                {isLoading ? (
                  <SubmitIn>
                    <ButtonLoader />
                  </SubmitIn>
                ) : (
                  <CoverSubmit>
                    <Submit type="submit" value="Submit" />
                  </CoverSubmit>
                )}
              </FormSection>
            </Right>
          </Content>
        </Wrapper>
      </Fade>
    </Container>
  );
}

export default GetInTouch;

const Container = styled.div`
  padding: 80px 0;
  background-color: #f4f4f4;
  @media all and (max-width: 400px) {
    padding: 30px 0;
  }
`;
const Wrapper = styled.div``;
const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media all and (max-width: 1080px) {
    flex-wrap: wrap;
  }
`;
const Left = styled.div`
  width: 30%;
  @media all and (max-width: 1600px) {
    width: 50%;
  }
  @media all and (max-width: 1080px) {
    width: 100%;
  }
`;
const Sub = styled.h4`
  color: var(--blue);
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--blue);
  width: fit-content;
`;
const Title = styled.h2`
  color: var(--blue);

  font-family: "mont-bold";
  font-size: 35px;
  @media all and (max-width: 1600px) {
    font-size: 32px;
  }
  @media all and (max-width: 1080px) {
    br {
      display: none;
    }
  }
  @media all and (max-width: 480px) {
    font-size: 28px;
  }
  @media all and (max-width: 360px) {
    font-size: 24px;
  }
`;
const Address = styled.div`
  @media all and (max-width: 1080px) {
    margin-bottom: 30px;
  }
`;
const Heading = styled.div`
  color: var(--blue);
  margin-bottom: 10px;
  @media all and (max-width: 400px) {
    margin-top: 10px;
  }
`;
const Span = styled.div``;
const Right = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
const ImageContainer = styled.div`
  margin-right: 80px;

  img {
    display: block;
    width: 100%;
  }
  @media all and (max-width: 1600px) {
    width: 25%;
  }
  @media all and (max-width: 640px) {
    display: none;
  }
`;
const FormSection = styled.form`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
`;
const FormTitle = styled.h2`
  margin-bottom: 20px;
`;
const Input = styled.input`
  width: 100%;
  border: 1px solid #adadad;
  border-radius: 5px;
  height: 45px;
  margin-bottom: 20px;
  box-sizing: border-box;
  padding-left: 20px;
  outline: none !important;
`;
const CoverSubmit = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;
const SubmitIn = styled.div`
  width: 150px;
  border: none;
  background-color: var(--blue);
  color: #fff;
  border-radius: 5px;
  font-size: 16px;
  font-family: "mont-regular";
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  cursor: pointer;
`;
const Submit = styled.input`
  width: 150px;
  border: none;
  background-color: var(--blue);
  color: #fff;
  font-size: 16px;
  font-family: "mont-regular";
  height: 45px;
  display: flex;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  cursor: pointer;
`;
const TextArea = styled.textarea`
  width: 100%;
  outline: none !important;
  padding-left: 20px;
  border-radius: 5px;
  box-sizing: border-box;
  padding-top: 20px;
  height: 100px;
`;
