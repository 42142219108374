import React from "react";
import CountUp from "react-countup";
import styled from "styled-components";

import { Fade, Roll } from "react-reveal";
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";

//imports
import learn from "../../../assets/images/about/About.png";
import arrow from "../../../assets/images/arrow/Asset 7.png";

function About() {
  const [ref, inView] = useInView({
    triggerOnce: true, // Only trigger once when the section comes into view
  });
  return (
    <Container>
      <Wrapper className="wrapper">
        <Content>
          <TextSection>
            <Fade left delay={300}>
              <Sub>
                Welcome to <br /> English Cafe
              </Sub>
              <Title>
                We present you with <br /> perfect English
              </Title>
              <Paragraph>
              English Cafe, Calicut is undoubtedly the first and best destination all English learners could find. We follow premium standards of learning facilities that make our students' learning process simple and quick. What enables us to have the uppercut amidst the plethora of English teaching platforms out there, is our unique training modes and features. We create for our students ideal and flexible learning environments, which can be followed by learners of all backgrounds and ages. Personalized coaching, student-centred teaching method and practical sessions ensure results.
              </Paragraph>
              <TitleSec to="/about">
                <span>ABOUT US</span>
                <Icon>
                  <img
                    src={arrow}
                    alt="english speaking course for beginners"
                  />
                </Icon>
              </TitleSec>
            </Fade>
          </TextSection>
          <Image>
            <Roll right>
              <img src={learn} alt="best english learning app" />
            </Roll>
          </Image>
        </Content>
        <AboutSEction ref={ref}>
          <Count>
            <Card type="first">
              <CountItem>
                {inView && (
                  <>
                    <CountUp end={100}></CountUp>
                    <span>K+</span>
                  </>
                )}
              </CountItem>
              <Span>Successful Students</Span>
            </Card>
            <Card type="blue">
              <CountItem>
                {inView && (
                  <>
                    <CountUp end={100}></CountUp>
                    <span>+</span>
                  </>
                )}
              </CountItem>
              <Span>Language Trainers</Span>
            </Card>
            <Card type="third">
              <CountItem>
                {inView && (
                  <>
                    <CountUp end={10}></CountUp>
                    <span>+</span>
                  </>
                )}
              </CountItem>
              <Span>Awards Won</Span>
            </Card>
            <Card type="blue">
              <CountItem>
                {inView && (
                  <>
                    <CountUp end={10}></CountUp>
                    <span>+</span>
                  </>
                )}
              </CountItem>
              <Span>English Courses</Span>
            </Card>
          </Count>
        </AboutSEction>
      </Wrapper>
    </Container>
  );
}

export default About;

const Container = styled.div`
  padding: 80px 0;
  @media all and (max-width: 400px) {
    padding: 30px 0;
  }
`;
const Wrapper = styled.div``;
const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media all and (max-width: 1080px) {
    flex-direction: column;
  }
`;
const TextSection = styled.div`
  width: 50%;
  @media all and (max-width: 1080px) {
    margin-bottom: 50px;
  }
  @media all and (max-width: 1080px) {
    width: 100%;
  }
`;
const Sub = styled.div`
  border-bottom: 1px solid #0e2d5e;
  padding-bottom: 10px;
  display: inline-block;
  color: #0e2d5e;
  margin-bottom: 20px;
`;
const Title = styled.h1`
  color: #0e2d5e;
  font-family: "mont-bold";
`;
const Paragraph = styled.p`
  color: #000101;
  line-height: 28px;
  text-align: justify;
`;
const Image = styled.div`
  width: 40%;
  img {
    display: block;
    width: 100%;
  }
  @media all and (max-width: 1080px) {
    width: 60%;
  }
  @media all and (max-width: 980px) {
    width: 50%;
  }
  @media all and (max-width: 480px) {
    width: 100%;
  }
`;
const AboutSEction = styled.div`
  width: 90%;
  margin: 0 auto;
  @media all and (max-width: 1440px) {
    margin-top: 50px;
  }
  @media all and (max-width: 768px) {
    width: 100%;
  }
`;
const TitleSec = styled(Link)`
  display: flex;
  cursor: pointer;
  margin-top: 20px;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  position: relative;
  width: fit-content;
  span {
    border-top: 1px solid #d50b00;
    border-bottom: 1px solid #d50b00;
    padding-right: 20px;
    padding: 8px 20px 5px 5px;
    color: #0e2d5e;
    font-family: "mont-medium";
    font-size: 14px;
  }
`;
const Icon = styled.div`
  position: absolute;
  right: -22px;
  img {
    display: block;
  }
`;
const Count = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media all and (max-width: 480px) {
    flex-wrap: wrap;
  }
`;
const Card = styled.div`
  background-color: ${({ type }) => (type === "blue" ? "#0E2D5E" : " #ff4945")};
  width: 30%;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 280px;
  flex-direction: column;
  cursor: pointer;
  transition: 0.4s ease;
  &:hover {
    transform: translate(0px, 20px);
    transition: 0.4s ease;
  }
  &:first-child {
    border-radius: 50px 0 0 50px;
  }
  &:last-child {
    border-radius: 0 50px 50px 0;
    @media all and (max-width: 480px) {
      border-radius: 0 0 50px 0;
      background-color: #ff4945;
    }
  }

  @media all and (max-width: 1080px) {
    width: 24%;
    height: 220px;
    margin-right: 5px;
  }
  @media all and (max-width: 480px) {
    width: 40%;
    height: 180px;
    background-color: ${({ type }) => type === "third" && "#0E2D5E"};
    &:nth-child(1) {
      border-radius: 50px 0 0 0;
      margin-bottom: 5px;
      &:hover {
        transform: translate(-10px, 0px);
        transition: 0.4s ease;
      }
    }
    &:nth-child(2) {
      margin-bottom: 5px;
      border-radius: 0 50px 0 0;
      &:hover {
        transform: translate(10px, 0px) !important;
        transition: 0.4s ease;
      }
    }
    &:nth-child(3) {
      border-radius: 0 0 0 50px;
      &:hover {
        transform: translate(-10px, 0px);
        transition: 0.4s ease;
      }
    }
    &:nth-child(4) {
      &:hover {
        transform: translate(10px, 0px) !important;
        transition: 0.4s ease;
      }
    }
  }
`;
const CountItem = styled.h1`
  color: #fff;
  font-size: 75px;
  font-family: "mont-bold";
  margin: 0;
  @media all and (max-width: 1080px) {
    font-size: 50px;
  }
  @media all and (max-width: 480px) {
    font-size: 40px;
  }
  @media all and (max-width: 400px) {
    font-size: 35px;
  }
`;
const Span = styled.span`
  color: #fff;
  @media all and (max-width: 1080px) {
    font-size: 14px;
    text-align: center;
  }
  @media all and (max-width: 400px) {
    font-size: 12px;
  }
`;
