import React, { useEffect, useState } from "react";
import { Fade, Zoom } from "react-reveal";
import api from "../../general/api";
import { Helmet } from "react-helmet";

import Header from "../../includes/home/Header";
import styled from "styled-components";
import Footer from "../../includes/home/Footer";
import bg from "../../../assets/images/careers/Banner.jpg";
import Slider from "react-slick";
import tab_bg from "../../../assets/images/careers/Banner_Tab.jpg";
import mob_bg from "../../../assets/images/careers/Banner_Mob.jpg";
import careerImg from "../../../assets/images/careers/CareerIcon.svg";
import EnquiryForm from "../../includes/modal/EnquiryForm";
import NoCareers from "../../includes/nodata/NoCareers";
import { accountsConfig } from "../../../AxiosConfig";

function Careers() {
  const [isModal, setModal] = useState(false);
  const [getCareers, setCareers] = useState([]);
  const [getId, setId] = useState("");
  const [getTitle, setTitle] = useState("");
  const [getDescription, setDescription] = useState("");

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  //career list api
  useEffect(() => {
    accountsConfig.get("company_profile/get-careers/", {}).then((res) => {
      const StatusCode = res.data.app_data.StatusCode;
      const Data = res.data.app_data.data;
      if (StatusCode == 6000) {
        setCareers(Data);
      } else {
        setCareers([]);
      }
    });
  }, []);
  return (
    <>
      <Helmet>
        <title>Careers | English Cafe</title>
        <meta
          name="description"
          content="Unlock Your Future at Our English Training Center - Explore Rewarding Career Opportunities and Professional Growth with Us"
        ></meta>
      </Helmet>
      <Container>
        <Banner>
          <Header />
          <Zoom>
            <Text>Careers</Text>
          </Zoom>
        </Banner>
        <Fade bottom>
          <Content>
            <Wrapper>
              <LeftSection>
                <SubTitle>Careers</SubTitle>
                <Title>Join Our Team and Make an Impact.</Title>
                <Description>
                  We foster a genuine passion for helping people improve their
                  skills. Explore our latest team vacancies. We are always on
                  the lookout for driven individuals, dedicated to empowering
                  learners in communicating confidently and effectively.
                </Description>
              </LeftSection>
              <RightSection>
                {getCareers?.length == 0 ? (
                  <NoCareers />
                ) : (
                  <Slider {...settings}>
                    {getCareers?.map((item, index) => (
                      <Card key={index}>
                        <Span>{item.job_type}</Span>
                        <Icon>
                          <img src={careerImg} alt={item.designation} />
                        </Icon>
                        <CardTitle>{item.designation}</CardTitle>
                        <Button
                          onClick={() => {
                            setModal(true);
                            setId(item.id);
                            setTitle(item.designation);
                            setDescription(item.job_description);
                          }}
                        >
                          View & Apply
                        </Button>
                      </Card>
                    ))}
                  </Slider>
                )}
              </RightSection>
            </Wrapper>
          </Content>
        </Fade>
        <EnquiryForm
          setModal={setModal}
          isModal={isModal}
          id={getId}
          title={getTitle}
          description={getDescription}
        />
        <CallBanner>
          <span>Our Call Center :</span>
          <a href="tel:+91 7907139138">+91 7907139138</a>
        </CallBanner>
      </Container>
      <Footer />
    </>
  );
}

export default Careers;

const Container = styled.div``;
const Wrapper = styled.div`
  width: 70%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  @media all and (max-width: 1440px) {
    width: 80%;
  }
  @media all and (max-width: 1080px) {
    flex-direction: column;
  }
`;
const Banner = styled.div`
  background: url(${bg});
  height: 62vh;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  @media all and (max-width: 1560px) {
    background-position: center;
  }
  @media all and (max-width: 1280px) {
    background-position: 30%;
  }
  @media all and (max-width: 768px) {
    background: url(${tab_bg});
  }
  @media all and (max-width: 480px) {
    background: url(${mob_bg});
    background-position: center;
    background-size: contain;
  }
`;
const Content = styled.div`
  padding: 40px 0;
`;
const Text = styled.h1`
  color: #fff;
  font-family: mont-medium;
  font-size: 50px;
  margin-left: 350px;
  margin-top: 150px;
  @media all and (max-width: 768px) {
    display: none;
  }
`;
const LeftSection = styled.div`
  background-color: rgb(245, 244, 245);
  width: 34%;
  /* height: 58px 30px; */
  /* min-height: 485px; */
  padding: 60px 30px;
  box-sizing: border-box;
  @media all and (max-width: 1080px) {
    width: 100%;
    padding: 60px 30px 30px 30px;
  }
`;
const SubTitle = styled.h4`
  color: var(--blue);
  border-bottom: 1px solid var(--blue);
  padding-bottom: 10px;
  display: inline-block;
  font-family: "mont-medium";
  margin-bottom: 60px;
  @media all and (max-width: 400px) {
    margin-bottom: 10px;
  }
`;
const Description = styled.p`
  font-size: 14px;
  line-height: 30px;
`;
const RightSection = styled.div`
  width: 65%;
  background-color: #f5f4f5;
  height: 58px 30px;
  /* min-height: 485px; */
  padding: 60px 30px;
  box-sizing: border-box;
  @media all and (max-width: 1080px) {
    width: 100%;
    padding: 30px;
  }
  @media all and (max-width: 480px) {
    min-height: unset;
  }
`;
const Card = styled.div`
  background-color: #fff;
  padding: 30px 0;
  display: flex !important;
  flex-direction: column;
  border-radius: 30px;
  justify-content: center;
  align-items: center;
  width: 90% !important;
  margin: 0 auto;
`;
const Span = styled.span`
  color: var(--blue);
  border: 1px solid #0e2d5e;
  padding: 6px 10px;
  /* margin-bottom: 30px; */
  border-radius: 30px;
  font-size: 12px;
`;
const Icon = styled.div`
  width: 40%;
  margin-top: 30px;
  img {
    display: block;
    width: 100%;
  }
`;
const CardTitle = styled.h3`
  font-family: "mont-medium";
  color: var(--blue);
  margin: 30px 0 20px 0;
`;
const Title = styled.h2`
  color: var(--blue);
  font-family: "mont-bold";
`;
const Button = styled.div`
  background-color: #ff4844;
  color: #fff;
  width: fit-content;
  padding: 6px 14px;
  font-family: "mont-medium";
  cursor: pointer;
  border-radius: 20px;
  font-size: 12px;
`;
const CallBanner = styled.div`
  background-color: #f5f4f5;
  padding: 30px 0;
  text-align: center;
  span {
    color: #d92119;
    font-size: 30px;
    @media all and (max-width: 480px) {
      font-size: 18px;
    }
  }
  a {
    margin-left: 10px;
    color: var(--blue);
    font-family: "mont-bold";
    text-decoration: none;
    font-size: 35px;
    @media all and (max-width: 480px) {
      font-size: 20px;
    }
  }
  @media all and (max-width: 480px) {
    padding: 20px 0;
  }
`;
