import React, { useEffect, useState } from "react";
import Header from "../../includes/home/Header";
import styled from "styled-components";
import { careers_list, founders, trainers } from "../../general/arrays";
import Footer from "../../includes/home/Footer";
import bg from "../../../assets/images/ourteam/Banner.jpg";
import Slider from "react-slick";
import { Fade, Zoom } from "react-reveal";
import { shuffleInit } from "../../../assets/js/script";
import ShuffleText from "react-shuffle-text";
import api from "../../general/api";
import { Helmet } from "react-helmet";
import { accountsConfig } from "../../../AxiosConfig";
function OurTeam() {
  const [isHover, setHover] = useState(false);
  const [getOurTeam, setOurTeam] = useState([]);
  const [displayedItems, setDisplayedItems] = useState(4);

  const showMoreAchievements = () => {
    setDisplayedItems(getOurTeam.length);
  };

  useEffect(() => {
    accountsConfig.get("company_profile/get-our-team/").then((res) => {
      const Data = res.data.app_data.data;
      if (res.data.app_data.StatusCode == 6000) {
        setOurTeam(Data);
      }
    });
  }, []);
  return (
    <>
      <Helmet>
        <title>Our Team | English Cafe</title>
        <meta
          name="description"
          content="Meet the dedicated professionals behind our English Training Academy, committed to helping you achieve language proficiency and personal growth."
        ></meta>
      </Helmet>
      <Container>
        <Banner>
          <Header />
          <Zoom>
            <Text>Our Team</Text>
          </Zoom>
        </Banner>
        <Fade bottom>
          <Content>
            <Wrapper>
              <Founders>
                <SubTitle>Founders</SubTitle>
                <Profiles>
                  {founders.map((item) => (
                    <Profile
                      onMouseEnter={() => setHover(item.id)}
                      onMouseLeave={() => setHover()}
                    >
                      <Image>
                        <img src={item.image} alt={item.alt} />
                        <FirstLetter className="shuffle">
                          {isHover == item.id ? (
                            <ShuffleText content={item.name.charAt(0)} />
                          ) : (
                            item.name.charAt(0)
                          )}
                        </FirstLetter>
                      </Image>
                      <Name>{item.name} </Name>
                      <Designation>{item.designation} </Designation>
                    </Profile>
                  ))}
                </Profiles>
              </Founders>
              {getOurTeam?.map((item) => (
                <Trainers>
                  {item.team_members == undefined ? (
                    ""
                  ) : (
                    <SubTitle>{item.name}</SubTitle>
                  )}
                  <Profiles>
                    {item?.team_members?.map((x) => (
                      <Profile
                        onMouseEnter={() => setHover(x.id)}
                        onMouseLeave={() => setHover()}
                      >
                        <Image>
                          <img src={x.photo} alt={x.alt} />
                          <FirstLetter className="shuffle">
                            {isHover == x.id ? (
                              <ShuffleText content={x.name.charAt(0)} />
                            ) : (
                              x.name.charAt(0)
                            )}
                          </FirstLetter>
                        </Image>
                        <Name>{x.name} </Name>
                        <Designation>
                          {x.designation} {x.head && "(Head)"}
                        </Designation>
                      </Profile>
                    ))}
                  </Profiles>
                </Trainers>
              ))}
              {/* {getOurTeam.length > 4 && displayedItems < getOurTeam.length && (
                <View onClick={() => showMoreAchievements()}>
                  View all
                  <Icon>
                    <i className="ri-arrow-right-s-line"></i>
                  </Icon>
                </View>
              )} */}
            </Wrapper>
          </Content>
        </Fade>
        <CallBanner>
          <span>Our Call Center :</span>
          <a href="tel:+91 7907139138">+91 7907139138</a>
        </CallBanner>
      </Container>
      <Footer />
    </>
  );
}

export default OurTeam;

const Container = styled.div``;
const FirstLetter = styled.h1`
  color: rgb(255, 255, 255);
  font-size: 130px;
  font-family: mont-bolder;
  position: absolute;
  bottom: -47px;
  left: 30px;
  @media all and (max-width: 1080px) {
    bottom: -36px;
    font-size: 100px;
  }
`;
const Wrapper = styled.div`
  width: 70%;
  margin: 0 auto;
  @media all and (max-width: 1440px) {
    width: 80%;
  }
  @media all and (max-width: 1080px) {
    flex-direction: column;
  }
`;
const Banner = styled.div`
  background: url(${bg});
  height: 62vh;
  background-repeat: no-repeat;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @media all and (max-width: 1800px) {
    background-position: center;
  }
  @media all and (max-width: 1280px) {
    background-position: 30%;
  }
  @media all and (max-width: 768px) {
    justify-content: center;
    align-items: center;
  }
`;
const Content = styled.div`
  padding: 40px 0;
`;
const Text = styled.h1`
  color: #fff;
  font-family: mont-medium;
  font-size: 50px;
  margin-right: 400px;
  margin-top: -10px;
  @media all and (max-width: 1700px) {
    margin-right: 300px;
  }
  @media all and (max-width: 1440px) {
    margin-right: 200px;
  }
  @media all and (max-width: 768px) {
    margin-right: 0;
  }
`;
const SubTitle = styled.h4`
  color: var(--blue);
  border-bottom: 1px solid var(--blue);
  padding-bottom: 10px;
  display: inline-block;
  font-family: "mont-medium";
  margin-bottom: 30px;
  @media all and (max-width: 400px) {
    margin-bottom: 20px;
  }
`;
const CallBanner = styled.div`
  background-color: #f5f4f5;
  padding: 30px 0;
  text-align: center;
  span {
    color: #d92119;
    font-size: 30px;
    @media all and (max-width: 480px) {
      font-size: 18px;
    }
  }
  a {
    margin-left: 10px;
    color: var(--blue);
    font-family: "mont-bold";
    text-decoration: none;
    font-size: 35px;
    @media all and (max-width: 480px) {
      font-size: 20px;
    }
  }
  @media all and (max-width: 480px) {
    padding: 20px 0;
  }
`;
const View = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
  align-items: center;
  text-transform: capitalize;
  color: var(--blue);
  cursor: pointer;
  font-family: "mont-bold";
  i {
    color: var(--red);
    font-family: "mont-bold";
  }
`;
const Icon = styled.div`
  border: 1px solid var(--red);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Founders = styled.div`
  margin-bottom: 80px;
`;
const Profiles = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;

  gap: 30px;
  margin-bottom: 30px;
`;
const Profile = styled.div`
  width: 23%;
  cursor: pointer;
  @media all and (max-width: 1280px) {
    width: 22%;
  }
  @media all and (max-width: 768px) {
    width: 47%;
  }
  @media all and (max-width: 480px) {
    width: 100%;
  }
`;
const Image = styled.div`
  position: relative;
  border-radius: 30px;
  img {
    border-radius: 5px;
  }
`;
const Name = styled.h3`
  color: var(--blue);
  font-family: "mont-bold";
  text-align: center;
  margin-top: 10px;
  margin-bottom: 3px !important;
`;
const Designation = styled.p`
  color: #000;
  font-size: 14px;
  margin: 0;
  font-family: "mont-light";
  text-align: center;
`;
const Trainers = styled.div``;
