import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Header from "./Header";
import bg from "../../../assets/images/spotlight/Banner.png";
import mob from "../../../assets/images/spotlight/BannerMob.png";
import arrow from "../../../assets/images/arrow/Asset 7.png";
import { Fade, Zoom } from "react-reveal";
import vineeth1 from "../../../assets/images/spotlight/vineeth1.png";
import vineeth2 from "../../../assets/images/spotlight/vineeth2.png";
import vineeth3 from "../../../assets/images/spotlight/vineeth3.png";
import vineeth4 from "../../../assets/images/spotlight/vineeth5.png";
import vineeth5 from "../../../assets/images/spotlight/vineeth6.png";
import Slider from "react-slick";
import { Context } from "../../context/store";
import WhatsappEnquiryModal from "../modal/WhatsappEnquiryModal";
import api from "../../general/api";;

function Spotlight({ isModal, closeFunction, number }) {
  const {
    state: { user_data },
  } = useContext(Context);

  const isLogin = user_data.isLogin;
  const settings = {
    dots: true,
    infinite: true,
    loop: true,
    speed: 500,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 4000,
  };
  const [isComponentVisible, setIsComponentVisible] = useState(false);

  const componentRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsComponentVisible(entry.isIntersecting);
      },
      {
        root: null, // Use the viewport as the root
        rootMargin: "0px", // Adjust this if needed
        threshold: 0.1, // 10% visibility triggers the callback
      }
    );

    if (componentRef.current) {
      observer.observe(componentRef.current);
    }

    return () => {
      if (componentRef.current) {
        observer.unobserve(componentRef.current);
      }
    };
  }, [componentRef]);

  return (
    <div ref={componentRef}>
      {/* // ----------------web-------------------- */}
      <Container>
        <Header />
        <Wrapper className="wrapper">
          <Content>
            <Contentsection>
              <CoverContainer>
                <Fade bottom>
                  <Vineeth>
                    <Slider {...settings}>
                      <ImageContainer>
                        <img src={vineeth4} />
                      </ImageContainer>
                      <ImageContainer>
                        <img src={vineeth2} alt="english grammar course" />
                      </ImageContainer>
                      <ImageContainer>
                        <img src={vineeth3} alt="english cafe" />
                      </ImageContainer>
                      <ImageContainer>
                        <img src={vineeth1} alt="ielts training online" />
                      </ImageContainer>
                      <ImageContainer>
                        <img src={vineeth4} />
                      </ImageContainer>
                      <ImageContainer>
                        <img
                          src={vineeth3}
                          alt="ielts coaching centre in calicut"
                        />
                      </ImageContainer>
                    </Slider>
                  </Vineeth>
                </Fade>

                <Zoom delay={300}>
                  <Cover>
                    <Text>
                      WITH ENGLISH CAFE, <br /> YOU CAN:
                    </Text>
                    <Sub>Speak with more accuracy and fluency.</Sub>
                    <Login
                      href={`https://api.whatsapp.com/send?phone=${number.phone}&text=Hi, I would like to know more.`}
                      target="_blank"
                    >
                      <span>Get Started</span>
                      <Icon>
                        <img src={arrow} alt="learn english online" />
                      </Icon>
                    </Login>
                  </Cover>
                </Zoom>
              </CoverContainer>
            </Contentsection>
          </Content>
        </Wrapper>
      </Container>
      {/* // ------------------mobile-------------------------- */}
      <ContainerMob>
        <Header />
        <Wrapper className="wrapper">
          <Content>
            <Contentsection>
              <Zoom delay={300}>
                <Cover>
                  <Text>
                    WITH ENGLISH CAFE, <br /> YOU CAN:
                  </Text>
                  <Sub>Speak with more accuracy and fluency.</Sub>
                  <Login
                    href={`https://api.whatsapp.com/send?phone=${number}&text=Hi, I would like to know more.`}
                  >
                    <span>Get Started</span>
                    <Icon>
                      <img src={arrow} alt="online english speaking classes" />
                    </Icon>
                  </Login>
                </Cover>
              </Zoom>
            </Contentsection>
          </Content>
        </Wrapper>
      </ContainerMob>
      {!isLogin && isComponentVisible && (
        <WhatsappEnquiryModal setModal={closeFunction} isModal={isModal} />
      )}
    </div>
  );
}

export default Spotlight;

const Container = styled.div`
  background: url(${bg});
  background-size: cover;
  z-index: 100;
  background-repeat: no-repeat;
  min-height: 100vh;
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  @media all and (max-width: 1600px) {
    background-position: center;
  }
  @media all and (max-width: 1080px) {
    display: none;
  }
`;
const ContainerMob = styled.div`
  display: none;
  @media all and (max-width: 1080px) {
    background: url(${mob});
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
    position: relative;
    display: flex;
  }
  @media all and (max-width: 640px) {
    justify-content: flex-end;
    align-items: flex-end;
  }
  @media all and (max-width: 400px) {
    background-position-x: 10%;
  }
`;
const Wrapper = styled.div`
  width: 90% !important;
`;
const CoverContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: hidden;
  /* background-color: aqua; */
`;
const Vineeth = styled.div`
  width: 800px !important;
  overflow: hidden;
  /* background: url(${vineeth1}); */
  /* animation: change 10s infinite;
  animation-direction: alternate; */
  /* animation-delay: 3s; */
  /* @keyframes change {
    0% {
      background: url(${vineeth1});
    }
    50% {
      background: url(${vineeth2});
    }
    100% {
      background: url(${vineeth3});
    }
  } */
  img {
    /* visibility: hidden; */
  }
`;
const Cover = styled.div``;
const Content = styled.div`
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media all and (max-width: 1080px) {
    justify-content: flex-end;
    align-items: flex-end;
  }
  @media all and (max-width: 640px) {
    justify-content: center;
    align-items: center;
  }
`;
const Image = styled.div``;
const Contentsection = styled.div`
  /* margin-left: 250px; */
  @media all and (max-width: 768px) {
    margin-left: 366px;
  }
  @media all and (max-width: 640px) {
    margin-bottom: 50px;
    margin-left: 0;
  }
`;
const Text = styled.h1`
  font-size: 60px;
  font-family: "mont-bold";
  margin-bottom: 10px;
  @media all and (max-width: 1440px) {
    font-size: 48px;
  }
  @media all and (max-width: 1080px) {
    font-size: 45px;
  }
  @media all and (max-width: 768px) {
    font-size: 26px;
  }
  @media all and (max-width: 640px) {
    font-size: 50px;
  }
  @media all and (max-width: 480px) {
    font-size: 32px;
  }
`;
const Sub = styled.h3`
  font-size: 24px;
  margin-bottom: 20px;
  @media all and (max-width: 1080px) {
    font-size: 20px;
  }
  @media all and (max-width: 768px) {
    font-size: 14px;
    margin-bottom: 50px;
  }
  @media all and (max-width: 640px) {
    margin-bottom: 35px;
  }
`;
const Login = styled.a`
  background-color: #fff;
  color: #d50b00;
  text-decoration: none;
  cursor: pointer;
  padding: 6px 6px 6px 20px;
  border-radius: 0 30px 30px 0;
  display: flex;
  /* display: inline-block; */
  width: fit-content;
  span {
    position: relative;
    margin-right: 34px;
    font-family: "mont-medium";
    font-size: 26px;
    &::after {
      position: absolute;
      content: "";
      width: 30px;
      height: 2px;
      background-color: #d50b00;
      top: 16px;
      right: -35px;
      @media all and (max-width: 1080px) {
        top: 15px;
      }
      @media all and (max-width: 480px) {
        top: 15px;
      }
    }
    @media all and (max-width: 1080px) {
      font-size: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    @media all and (max-width: 640px) {
      /* margin: 0; */
    }
    @media all and (max-width: 480px) {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  transition: 0.4s ease;
  &:hover {
    transform: scale(1.1);
    transition: 0.4s ease;
  }
  @media all and (max-width: 640px) {
    /* margin: 0 auto; */
  }
  @media all and (max-width: 480px) {
    /* margin: auto 0; */
    margin: 0;
  }
`;
const Icon = styled.div`
  img {
    display: block;
  }
`;
const ImageContainer = styled.div`
  width: 100% !important;
  img {
    width: 100%;
  }
`;
