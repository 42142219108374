//imports
import React, { useContext, useEffect, useState } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import api from "../../general/api";
import { Context } from "../../context/store";
import styled from "styled-components";
import "sweetalert2/dist/sweetalert2.css";

//assets
import bg from "../../../assets/images/dashboard/bg/BG.svg";
import logo from "../../../assets/images/header/Asset 5.png";
import profile from "../../../assets/images/dashboard/icons/Profile.svg";
import logout from "../../../assets/images/dashboard/icons/Logout.svg";
import home from "../../../assets/images/profile/Home.svg";

//components
import TextBreif from "../../includes/dashboard/text/TextBreif";
import ImageView from "../../includes/dashboard/imageview/ImageView";
import AudioWaveform from "../../includes/dashboard/audio/AudioWaveform";
import VideoView from "../../includes/dashboard/videoview/VideoView";
import SuccessModal from "../../includes/modal/SuccessModal";
import Swal from "sweetalert2/dist/sweetalert2";

function Dashboard() {
  //arrays
  const [totalDays, setTotalDays] = useState([]);
  const [getDailyTopics, setDailyTopics] = useState([]);

  //assign
  const [dayId, setDayId] = useState();
  const [topicId, setTopicID] = useState();
  const [courseDetail, setCourseDetail] = useState();
  const [hide, setHide] = useState();
  const [focus, setFocus] = useState();
  const [programmeCompleteStatus, setProgrammeCompleteStatus] = useState();

  //boolean
  const [isRender, setRender] = useState(false);
  const [isModal, setModal] = useState(false);
  const [isShow, setShow] = useState(false);
  const [isComplete, setComplete] = useState(false);
  const [isPlaying, setPlaying] = useState(false);
  const [isAlert, setAlert] = useState(false);
  const [isLoading, setloading] = useState(false);

  //import data from store
  const {
    state: { user_data },
  } = useContext(Context);

  const access_token = user_data.access_token;
  const navigate = useNavigate();

  //logout
  const logoutFunction = () => {
    window.localStorage.clear();
    navigate("/");
    window.location.reload();
  };
  //get Daily topics
  useEffect(() => {
    dayId &&
      api
        .get(`courses/get-daily-topics/${dayId}`, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })
        .then((res) => {
          const Data = res.app_data.data;
          if (res.app_data.StatusCode == 6000) {
            setDailyTopics(Data);
            setRender(false);
            setAlert(false);
          } else {
          }
        });
  }, [isRender, dayId, topicId]);
  //get total days
  useEffect(() => {
    api
      .get("courses/total-days/", {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((res) => {
        console.log(res,"respooooo");
        const Data = res.app_data.data;
        if (res.app_data.StatusCode == 6000) {
          setTotalDays(Data);
        }
      });
  }, [isRender]);

  //daily single topic complete
  const handleCompleteStatus = (id) => {
    setloading(true);
    setTopicID(id);
    api
      .post(
        `courses/daily-topic-complete/${id}/`,
        {},
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      )
      .then((response) => {
        const data = response.data.app_data.data;
        if (response.data.app_data.StatusCode === 6000) {
          setRender(true);
          setloading(false);
          setAlert(false);
          if (data.message == "Milestone Reached!") {
            setModal(true);
            setPlaying(true);
            setPlaying(true);

            setTimeout(() => {
              setPlaying(false);
              setAlert(true);
              setModal(false);
            }, 3500);
          }
        } else {
          setloading(false);
        }
      })
      .catch((error) => {});
  };

  //mark as complete
  const handleMarkasComplete = () => {
    api
      .post(
        `courses/mark-as-complete/${dayId}/`,
        {},
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      )
      .then((response) => {
        const Data = response.app_data.data;
        if (response.app_data.StatusCode === 6000) {
          setRender(true);
          setAlert(false);
          if (Data.programme_status) {
            programmeSuccessAlert();
          }
          if (Data.message == "Successfully completed the current day") {
            setDayId(Data.next_day_id);
            window.location.reload();
          }
        } else {
        }
      })
      .catch((error) => {});
  };
  //current day
  useEffect(() => {
    api
      .get(`courses/current-day/`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((res) => {
        const Data = res.app_data.data;
        if (res.app_data.StatusCode == 6000) {
          setDayId(Data.current_day_id);
          setComplete("");
          setProgrammeCompleteStatus(Data.Programme_complete_status);
        } else {
        }
      });
  }, []);

  const studentId = user_data.student_id;
  //student details and course details
  useEffect(() => {
    api
      .get(`accounts/student/${studentId}/`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((res) => {
        const Data = res.app_data.data;
        if (res.app_data.StatusCode == 6000) {
          setCourseDetail(Data);
        }
      });
  }, []);

  //success message when program completed
  const programmeSuccessAlert = () => {
    Swal.fire({
      position: "center",
      icon: "success",
      title: "Mission Accomplished!",
      showConfirmButton: true,
      // timer: 1500,
    }).then((res) => {
      if (res.isConfirmed) {
        navigate("/");
      }
    });
  };
  return (
    <MainContainer>
      <Wrapper className="wrapper">
        <Header>
          {!isShow && (
            <Logo>
              <img src={logo} alt="logo" />
            </Logo>
          )}
          <Accounts>
            <CoverAccounts to="profile">
              <Profile>
                <img src={profile} alt="profile-icon" />
              </Profile>
              <LabelText>Profile</LabelText>
            </CoverAccounts>
            <CoverAccounts to="/">
              <Profile className="home">
                <img src={home} alt="home-icon" />
              </Profile>
              <LabelText>Home</LabelText>
            </CoverAccounts>
            <CoverAccounts onClick={() => logoutFunction()}>
              <Profile>
                <img src={logout} alt="logout-icon" />
              </Profile>
              <LabelText>Logout</LabelText>
            </CoverAccounts>
          </Accounts>

          {/* ---------------mob----------- */}
          {isShow && (
            <MobAccounts>
              <CoverAccounts to="profile">
                <Profile>
                  <img src={profile} alt="profile-icon" />
                </Profile>
                <LabelText>Profile</LabelText>
              </CoverAccounts>
              <CoverAccounts to="/">
                <Profile className="home">
                  <img src={home} alt="home-icon" />
                </Profile>
                <LabelText>Home</LabelText>
              </CoverAccounts>
              <CoverAccounts onClick={() => logoutFunction()}>
                <Profile>
                  <img src={logout} alt="logout-icon" />
                </Profile>
                <LabelText>Logout</LabelText>
              </CoverAccounts>
            </MobAccounts>
          )}
          <Hamburger onClick={() => setShow(!isShow)}>
            <i className="ri-menu-fold-fill"></i>
          </Hamburger>
        </Header>
        <TopBar>
          <Text>{courseDetail?.programmes}</Text>
          <Duration>
            <span>
              Course <br /> Duration
              <br /> (In Days)
            </span>
            <Count>{courseDetail?.programme_duration}</Count>
          </Duration>
        </TopBar>
        <DayList>
          {totalDays?.map((item,index) => (
            <Day
            key={index}
              type={item.status}
              onClick={() => {
                setDayId(item.id);
                setHide(item.status);
                setFocus(item.id);
              }}
              className={focus == item.id && "active"}
            >
              <Label>DAY</Label>
              <DayCount>
                {item.day_number < 10 ? `0${item.day_number}` : item.day_number}
              </DayCount>
            </Day>
          ))}
        </DayList>
        <Topics>
          {getDailyTopics.map((item, index) => (
            <div
              key={index}
              onClick={() => {
                setComplete(item.next_topic_id);
                setRender(true);
                // setHide(item.status);
              }}
            >
              {item.type == "text"
                ? item.is_processed && (
                    <TextBreif
                      content={item.daily_text}
                      loading={isLoading}
                      hide={hide}
                      status={item.is_completed}
                      completeStatus={item}
                      handleFunction={handleCompleteStatus}
                    />
                  )
                : item.type == "audio"
                ? item.is_processed && (
                    <AudioWaveform
                      content={item.audio}
                      loading={isLoading}
                      hide={hide}
                      status={item.is_completed}
                      completeStatus={item}
                      handleFunction={handleCompleteStatus}
                    />
                  )
                : item.type == "image"
                ? item.is_processed && (
                    <ImageView
                      content={item.daily_image}
                      loading={isLoading}
                      hide={hide}
                      status={item.is_completed}
                      completeStatus={item}
                      handleFunction={handleCompleteStatus}
                    />
                  )
                : item.type == "video"
                ? item.is_processed && (
                    <VideoView
                      content={item.video}
                      hide={hide}
                      status={item.is_completed}
                      loading={isLoading}
                      completeStatus={item}
                      handleFunction={handleCompleteStatus}
                    />
                  )
                : ""}
            </div>
          ))}
          {hide !== "completed" && !programmeCompleteStatus && (
            <MarkAsComplete
              type={isComplete}
              onClick={() => handleMarkasComplete()}
            >
              Mark as Complete
            </MarkAsComplete>
          )}
        </Topics>
        <SuccessModal
          isModal={isModal}
          setModal={setModal}
          play={isPlaying}
          isAlert={isAlert}
        />
      </Wrapper>
      <Outlet />
    </MainContainer>
  );
}

export default Dashboard;

const MainContainer = styled.div`
  background: url(${bg});
  padding: 80px 0;
  height: calc(100vh - 160px);
  overflow: scroll;
  @media all and (max-width: 480px) {
    padding: 40px 0px;
    height: calc(100vh - 85px);
  }
`;
const Wrapper = styled.div`
  @media (max-width: 480px) {
    &.wrapper {
      width: 90% important;
    }
  }
`;
const Hamburger = styled.div`
  display: none;
  @media all and (max-width: 480px) {
    display: block;
  }
  color: var(--blue);
  font-size: 28px;
`;
const CoverAccounts = styled(Link)`
  margin-right: 20px;
  text-decoration: none;
  cursor: pointer;
  &:last-child {
    margin-right: 0;
  }
`;
const LabelText = styled.h3`
  font-size: 14px;
  color: #2a2a28;
  margin: 0;
`;
const Header = styled.div`
  background: #fff;
  display: flex;
  justify-content: space-between;
  border-radius: 20px;
  padding: 30px 60px;
  @media all and (max-width: 480px) {
    align-items: center;
    padding: 0 40px;
    height: 100px;
  }
`;
const Logo = styled.div`
  width: 100px;
  img {
    display: block;
    width: 100%;
  }
  @media all and (max-width: 480px) {
    width: 90px;
  }
`;
const MobAccounts = styled.div`
  display: none;
  @media all and (max-width: 480px) {
    display: flex;
    justify-content: space-between;
    width: 80%;
  }
`;
const Accounts = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 10%;
  @media (max-width: 480px) {
    display: none;
  }
`;
const Profile = styled.div`
  /* width: 20%; */
  width: 40px;
  margin-bottom: 10px;
  color: rgb(14, 45, 94);
  font-weight: normal;
  i {
    font-size: 28px;
  }
  &.home {
    background-color: rgb(245 245 245);
    padding: 10px;
    width: 20px;
    border-radius: 50%;
  }
`;
const Logout = styled.div`
  /* width: 20%; */
`;
const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
`;
const Text = styled.h1`
  color: var(--blue);
  font-family: "mont-bold";
  @media (max-width: 480px) {
    font-size: 26px;
  }
`;
const Duration = styled.div`
  display: flex;
  span {
    font-size: 14px;
  }
  @media (max-width: 480px) {
    font-size: 12px;
  }
`;
const Count = styled.div`
  background-color: var(--red);
  color: #fff;
  width: 80px;
  height: 60px;
  border-radius: 10px;
  font-size: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  @media (max-width: 480px) {
    width: 50px;
    height: 40px;
    font-size: 24px;
  }
`;
const Topics = styled.div`
  width: 80%;
  /* height: calc(100vh - 400px); */
  margin: 0 auto;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    width: 1px;
    background-color: var(--blue);
    left: -53px;
    height: 100%;
    @media (max-width: 480px) {
      left: -24px;
    }
  }
  @media (max-width: 480px) {
    width: 90%;
    margin-left: 30px;
  }
`;
const MarkAsComplete = styled.div`
  background-color: ${({ type }) => (type == null ? "#0e2d5e" : "grey")};
  width: fit-content;
  padding: 20px;
  color: #fff;
  cursor: ${({ type }) => (type == null ? "pointer" : " not-allowed")};
  display: flex;
  justify-content: center;
  border-radius: 12px;
  align-items: center;
  margin-bottom: 20px;
`;
const DayList = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-width: 100%;
  min-height: 130px;
  margin-bottom: 30px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
`;
const Day = styled.div`
  &.active {
    background-color: #fff;
    color: #000;
  }
  width: 75px;
  margin-right: 5px;
  pointer-events: ${({ type }) => (type === "locked" ? "none" : "")};
  border-radius: 12px;
  padding: 30px 10px 10px 10px;
  transform: ${({ type }) => type === "ongoing" && "translate(0 , 8px)"};
  color: ${({ type }) =>
    type == "ongoing" ? " #fff" : type == "completed" ? "#fff" : " #a5a5a5"};
  background: ${({ type }) =>
    type == "completed"
      ? "rgb(14, 45, 94)"
      : type == "ongoing"
      ? "rgb(255, 73, 69)"
      : "#fff"};
  cursor: ${({ type }) => (type === "locked" ? "not-allowed" : "pointer")};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const DayCount = styled.h3`
  font-size: 28px;
  @media (max-width: 480px) {
    font-size: 20px;
  }
`;
const Label = styled.h6``;
const Status = styled.div`
  width: 50px;
  cursor: pointer;
  background-color: rgb(164, 164, 164);
  border-radius: 8px;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  margin-left: 20px;
  img {
    width: 100%;
    display: block;
  }
`;
