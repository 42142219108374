import React, { useEffect, useRef, useState } from "react";
import singleTick from "../../../../assets/images/dashboard/icons/Single tick.svg";
import doubleTick from "../../../../assets/images/dashboard/icons/Double tick.svg";
import styled from "styled-components";
import ReactPlayer from "react-player";
import ButtonLoader from "../../loader/ButtonLoader";

const VideoView = ({
  content,
  completeStatus,
  handleFunction,
  status,
  loading,
  hide,
}) => {
  return (
    <Container>
      <VideoContainer>
        <ReactPlayer
          width="100%"
          height="100%"
          controls={true}
          config={{
            file: {
              attributes: { controlsList: "nodownload", crossOrigin: "true" },
            },
          }}
          url={content}
        />
      </VideoContainer>
      <Bottom>
        {hide == "completed" ? (
          <Statuss>
            <img src={doubleTick} alt="" />
          </Statuss>
        ) : (
          <Status
            type={status}
            className={loading && !status && "no-padding"}
            onClick={() => {
              handleFunction(completeStatus.id);
            }}
          >
            {loading && !status ? (
              <ButtonLoader />
            ) : status ? (
              <img src={doubleTick} alt="" />
            ) : (
              <img src={singleTick} alt="" />
            )}
          </Status>
        )}
      </Bottom>
      <Bullet></Bullet>
    </Container>
  );
};

export default VideoView;
const Container = styled.div`
  background-color: #fff;
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 20px;
  position: relative;
  width: 60%;
  display: flex;
  align-items: end;
  @media all and (max-width: 480px) {
    width: 88%;
  }
`;
const VideoContainer = styled.div`
  cursor: pointer;
  img {
    display: block;
    width: 100%;
  }
`;
const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
`;
const Status = styled.div`
  width: 35px;
  background-color: ${({ type }) => (type ? "#0e2d5e" : " rgb(164, 164, 164)")};
  cursor: pointer;
  border-radius: 8px;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  margin-left: 20px;
  img {
    width: 100%;
    display: block;
  }
  &.no-padding {
    padding: 0;
  }
`;
const Statuss = styled.div`
  width: 35px;
  cursor: pointer;
  pointer-events: none;
  /* background-color: red; */
  background-color: #0e2d5e;
  border-radius: 8px;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  margin-left: 20px;
  img {
    width: 100%;
    display: block;
  }
`;
const Bullet = styled.div`
  width: 15px;
  height: 15px;
  background-color: var(--blue);
  position: absolute;
  left: -60px;
  top: 30px;
  border-radius: 50%;
  @media (max-width: 480px) {
    left: -31px;
  }
`;
