import React from "react";
import styled from "styled-components";
import { Fade } from "react-reveal";
import accolades from "../../../assets/images/footer/accolades.svg";

//imports
import logo from "../../../assets/images/footer/logo.png";

function Footer() {
  return (
    <Container>
      <Fade bottom delay={400}>
        <Wrapper className="wrapper">
          <Content>
            <Logo>
              <img src={logo} alt="logo" />
            </Logo>
            <Cover>
              <Title>Address</Title>
              <Span>
                The training hub <br /> Opposite Apco Honda
                <br /> Mini bypass road, Puthiyara
                <br /> Calicut - 673004
              </Span>
            </Cover>
            <Cover>
              <Title>Contact</Title>
              <CoverContact>
                <span>Phone:</span>
                <a href="tel: +91 79071 39138"> +91 79071 39138</a>
              </CoverContact>
              <CoverContact>
                <span>Email:</span>
                <a href="mailto: englishcafeofl@gmail.com">
                  englishcafeofl@gmail.com
                </a>
              </CoverContact>
            </Cover>
            <Cover>
              <Title>Connect</Title>
              <Logos>
                <a href="https://www.youtube.com/@ENGLISHCAFEOFFICIAL/">
                  <i className="ri-youtube-line"></i>
                </a>
                <a href="https://www.facebook.com/englishcafetraininghub">
                  <i className="ri-facebook-line"></i>
                </a>
                <a href="https://www.instagram.com/english_cafe__/">
                  <i className="ri-instagram-line"></i>
                </a>
              </Logos>
            </Cover>
          </Content>
        </Wrapper>
      </Fade>
      <Banner>
        <p>Copyright © 2024 English Cafe. All Rights Reserved</p>
        <p className="line">|</p>
        <p className="designedby">Design And Developed By  Accolades Integrated   <img src={accolades} alt="" /></p>
        {/*Copyright © 2024 English Cafe. All Rights Reserved
        <Logosec>
          <img src={accolades} alt="" />
        </Logosec> */}
      </Banner>
    </Container>
  );
}

export default Footer;

const Container = styled.div`
  padding: 120px 0 180px 0;
  background-color: #123876;
  color: #f4f4f4ad;
  position: relative;
  @media all and (max-width: 480px) {
    padding: 40px 0 100px 0;
  }
`;
const Wrapper = styled.div``;
const Logosec = styled.div`
  width: 10%;
  margin-left: 10px;
  img {
    filter: contrast(0.5);
  }
`;
const Banner = styled.div`
  background-color: var(--blue);
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 20px 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  .designedby{
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
  .line{
    padding: 0px 10px;
  }
  img{
    width: 18px;
  }
  @media (max-width:842px){
    flex-direction: column;
    
    .line{
      display: none;
    }
    .designedby{
      margin-top: -10px;
    }
  }
  @media all and (max-width: 480px) {
    font-size: 12px;
    padding: 10px 0;
  }
  
`;
const Content = styled.div`
  display: flex;
  justify-content: space-between;
  /* align-items: baseline; */
  @media all and (max-width: 1080px) {
    flex-wrap: wrap;
  }
`;
const Logo = styled.div`
  @media all and (max-width: 400px) {
    width: 40%;
  }
`;
const Cover = styled.div`
  &:nth-child(4) {
    @media all and (max-width: 1080px) {
      margin-top: 50px;
    }
    @media all and (max-width: 768px) {
      margin-right: 50px;
    }
    @media all and (max-width: 480px) {
      /* margin-top: 20px; */
    }
  }
`;
const Title = styled.div`
  margin-bottom: 20px;
  color: #fff;
  @media all and (max-width: 480px) {
    margin-top: 30px;
  }
`;
const Span = styled.div`
  line-height: 28px;
`;
const CoverContact = styled.div`
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
  span {
    margin-right: 10px;
  }
  a {
    color: #f4f4f4ad;
    text-decoration: none;
  }
`;
const Logos = styled.div`
  display: flex;
  a {
    width: 35px;
    margin-right: 20px;
    height: 35px;
    border: 1px solid rgb(255, 255, 255);
    border-radius: 50%;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  i {
    color: #fff;

    color: rgb(255, 255, 255);
    font-size: 24px;
  }
`;
