import axios from "axios";

//DEMO SERVER
// export const accountsConfig = axios.create({
//   baseURL: "https://englishcafe-demo.accoladesweb.com/api/v1/",
// });

//LIVE SERVER
export const accountsConfig = axios.create({
  baseURL: "https://englishcafeacademy.in/api/v1/",
});
