import React from "react";
import { class_about } from "../../general/arrays";
import styled from "styled-components";
import provide from "../../../assets/images/provide/Asset 4.png";
import icon from "../../../assets/images/provide/icon.png";
import arrow from "../../../assets/images/arrow/Asset 7.png";
import { Fade } from "react-reveal";

function Provide() {
  return (
    <Container>
      <Fade bottom delay={400}>
        <Wrapper className="wrapper">
          <Content>
            <Left>
              <Sub>What we provide</Sub>
              <Title>
                English Cafe <br />
                Makes English
                <br /> Not-So-Scary
                <br /> Anymore!
              </Title>
              <Description>
                What better mastery than the one over the Global Language?
              </Description>
            </Left>
            <Middle>
              <img src={provide} alt="image" />
            </Middle>
            <Right>
              <Heading>Our top-notch classes are all about:</Heading>
              <List>
                {class_about.map((item) => (
                  <Item key={item.id}>
                    <Icon>
                      <img src={icon} alt="img" />
                    </Icon>
                    <Titles>{item.title} </Titles>
                  </Item>
                ))}
              </List>
              {/* <TitleSec>
                <span>DISCOVER NOW</span>
                <Icons>
                  <img src={arrow} alt="icon" />
                </Icons>
              </TitleSec> */}
            </Right>
          </Content>
        </Wrapper>
      </Fade>
    </Container>
  );
}

export default Provide;

const Container = styled.div`
  /* padding: 80px 0; */
`;
const Wrapper = styled.div``;
const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media all and (max-width: 1080px) {
    flex-wrap: wrap;
    margin-bottom: 60px;
  }
`;
const Left = styled.div`
  width: 25%;
  @media all and (max-width: 1080px) {
    width: 100%;
    margin-bottom: 30px;
  }
`;
const Titles = styled.div``;
const Sub = styled.h4`
  color: var(--blue);
  padding-bottom: 20px;
  border-bottom: 1px solid var(--blue);
  width: fit-content;
  margin-bottom: 20px;
  @media all and (max-width: 1080px) {
    margin-top: 60px;
  }
`;
const Title = styled.h1`
  font-family: "mont-bolder";
  font-size: 40px;
  color: var(--blue);
  margin-bottom: 50px !important;
  @media all and (max-width: 1440px) {
    font-size: 38px;
  }
  @media all and (max-width: 1360px) {
    font-size: 34px;
  }
  @media all and (max-width: 1080px) {
    max-width: 75%;
    margin-bottom: 20px !important;
    br {
      display: none;
    }
  }
  @media all and (max-width: 480px) {
    font-size: 30px;
    max-width: 100%;
  }
`;
const Description = styled.h3``;
const Middle = styled.div`
  width: 25%;
  img {
    display: block;
    width: 100%;
  }
  @media all and (max-width: 1080px) {
    width: 45%;
    margin-right: 50px;
  }
  @media all and (max-width: 480px) {
    width: 80%;
    margin: 0 auto;
  }
`;
const Right = styled.div`
  width: 25%;
  @media all and (max-width: 1080px) {
    width: 45%;
  }
  @media all and (max-width: 480px) {
    width: 100%;
    margin-top: 20px;
  }
`;
const Heading = styled.h2`
  margin-bottom: 20px;
  @media all and (max-width: 1080px) {
    font-size: 21px;
  }
`;
const List = styled.div``;
const Item = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;
const Icon = styled.div`
  width: 15px;
  margin-right: 10px;
  img {
    display: block;
    width: 100%;
  }
`;
const TitleSec = styled.div`
  cursor: pointer;
  display: flex;
  margin-top: 30px;
  justify-content: center;
  align-items: center;
  position: relative;
  width: fit-content;
  span {
    border-top: 1px solid #d50b00;
    border-bottom: 1px solid #d50b00;
    padding-right: 20px;
    padding: 8px 20px 5px 5px;
    color: #0e2d5e;
    font-family: "mont-medium";
    font-size: 14px;
  }
`;
const Icons = styled.div`
  position: absolute;
  right: -22px;
  img {
    display: block;
  }
`;
