import React from "react";
import Lottie from "react-lottie";
import styled from "styled-components";
import loaderAnimation from "../../general/lottie/animations/pageloader.json";
import gif from "../../../assets/gif/English-Cafe-Web-Loader.gif";

export default function PageLoader() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loaderAnimation,
    rendererSettings: {},
  };
  return (
    <Container>
      <ImageContainer>
        <img src={gif} alt="" />
      </ImageContainer>
    </Container>
  );
}
const ImageContainer = styled.div`
  width: 20%;
  margin: 0 auto;
  @media all and (max-width: 480px) {
    width: 45%;
  }
`;
const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
`;
