import React, { useState, useEffect, useContext, useRef } from "react";
import styled from "styled-components";
import wavesurfer from "wavesurfer.js";

//imports
import audioFile from "../../../../assets/audio/sample.mp3";
import play from "../../../../assets/images/dashboard/icons/Play.svg";
import pause from "../../../../assets/images/dashboard/icons/Pause.svg";
import singleTick from "../../../../assets/images/dashboard/icons/Single tick.svg";
import doubleTick from "../../../../assets/images/dashboard/icons/Double tick.svg";
import ButtonLoader from "../../loader/ButtonLoader";

const AudioWaveform = ({
  content,
  status,
  completeStatus,
  handleFunction,
  loading,
  hide,
}) => {
  const [playPause, setPlayPause] = useState(true);
  const [isWatched, setWatched] = useState(false);

  const wavesurferRef = useRef(null);
  // Create an instance of the wavesurfer outside of the useEffect
  const wavesurferObj = useRef(null);
  const waveformRef = useRef(null);

  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  const [playing, setPlaying] = useState(false);

  // Initialize the WaveSurfer object when the component mounts
  useEffect(() => {
    wavesurferObj.current = wavesurfer.create({
      container: waveformRef.current,
      scrollParent: true,
      autoCenter: true,
      url: content,
      height: 50,
      barWidth: 3,
      cursorWidth: 1,
      cursorColor: "#193d78",
      loopSelection: true,
      waveColor: "#193d78",
      progressColor: "#889cbc",
      responsive: true,
    });

    return () => {
      // Clean up when the component unmounts
      wavesurferObj.current.destroy();
    };
  }, [content]);

  const handlePlayPause = () => {
    if (wavesurferObj.current) {
      wavesurferObj.current.playPause();
      setPlaying(!playing);
    }
  };

  useEffect(() => {
    if (wavesurferObj.current) {
      const handleReady = () => {
        const audioDuration = wavesurferObj.current.getDuration();
        setDuration(audioDuration);
      };

      wavesurferObj.current.on("ready", handleReady);

      return () => {
        wavesurferObj.current.on("ready", handleReady);
      };
    }
  }, []);

  // Update the current time state periodically while playing
  useEffect(() => {
    if (wavesurferObj.current && playing) {
      const updateCurrentTime = () => {
        const currentTime = wavesurferObj.current.getCurrentTime();
        setCurrentTime(currentTime);
      };

      const timer = setInterval(updateCurrentTime, 1000);

      return () => {
        clearInterval(timer);
      };
    }
  }, [playing]);
  function formatTime(timeInSeconds) {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  }
  return (
    <AudioBar>
      <Controls onClick={() => setPlayPause(!playPause)}>
        {!playing ? (
          <PlayButton onClick={() => handlePlayPause()}>
            <img src={play} alt="play-icon" />
          </PlayButton>
        ) : (
          <PlayButton onClick={() => handlePlayPause()}>
            <img src={pause} alt="play-icon" />
          </PlayButton>
        )}
      </Controls>
      <CoverForm>
        <WaveForms>
          <section ref={wavesurferRef} className="waveform-container">
            <div>
              <div
                id={`waveform-${content}`}
                ref={waveformRef}
                className="custom-waveform"
              />
            </div>
          </section>
        </WaveForms>
        <Duration>
          <span>
            {formatTime(currentTime)} / {formatTime(duration)}
          </span>
        </Duration>
      </CoverForm>
      {hide == "completed" ? (
        <Statuss>
          <img src={doubleTick} alt="" />
        </Statuss>
      ) : (
        <Status
          type={status}
          className={loading && !status && "no-padding"}
          onClick={() => {
            setWatched(true);
            handleFunction(completeStatus.id);
          }}
        >
          {loading && !status ? (
            <ButtonLoader />
          ) : status ? (
            <img src={doubleTick} alt="" />
          ) : (
            <img src={singleTick} alt="" />
          )}
        </Status>
      )}
      <Bullet></Bullet>
    </AudioBar>
  );
};

export default AudioWaveform;

const AudioBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 10px 20px;
  border-radius: 12px;
  position: relative;
  margin-bottom: 20px;
  @media all and (max-width: 980px) {
    padding: 30px;
  }
  @media all and (max-width: 400px) {
    padding: 20px;
  }
`;
const Controls = styled.div`
  /* width: 20%; */
`;
const Bullet = styled.div`
  width: 15px;
  height: 15px;
  background-color: var(--blue);
  position: absolute;
  left: -60px;
  top: 30px;
  border-radius: 50%;
  @media (max-width: 480px) {
    left: -31px;
  }
`;
const PlayButton = styled.div`
  width: 40px;
  margin-right: 20px;
  cursor: pointer;
  img {
    width: 100%;
    display: block;
  }
  @media all and (max-width: 400px) {
    width: 35px;
    margin-right: 10px;
  }
`;
const CoverForm = styled.div`
  display: flex;
  width: 100%;
  @media (max-width: 980px) {
    flex-direction: column;
    position: relative;
  }
`;
const WaveForms = styled.div`
  width: 90%;
  @media (max-width: 480px) {
    width: 100%;
  }
`;
const Duration = styled.div`
  width: 20%;
  margin-left: 20px;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 980px) {
    width: fit-content;
    font-size: 14px;
    position: absolute;
    bottom: -15px;
    right: 0;
  }
`;
const Statuss = styled.div`
  width: 35px;
  cursor: pointer;
  pointer-events: none;
  /* background-color: red; */
  background-color: #0e2d5e;
  border-radius: 8px;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  margin-left: 20px;
  img {
    width: 100%;
    display: block;
  }
`;
const Status = styled.div`
  width: 35px;
  cursor: pointer;
  background-color: ${({ type }) => (type ? "#0e2d5e" : " rgb(164, 164, 164)")};
  border-radius: 8px;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  &.no-padding {
    padding: 0;
  }
  img {
    display: block;
    width: 100%;
  }
`;
